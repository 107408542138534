/**
 * Lamp Status values.
 * Will be serialized as number [0..3].
 * @todo Please check if values are correct.
 */
export enum LampStatus {
    Off = 0,
    On = 1,
    Blink_1Hz = 2,
    Blink_2Hz = 3
}

export interface Lamps {
    /** Malfunction Indicator Lamp */
    MIL: number

    /** Red Stop Lamp */
    RSL: number

    /** Amber Warning Lamp */
    AWL: number

    /** Protect Lamp */
    PL: number
}
