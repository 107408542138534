/**
 * Properties of a FeedingReport.
 */

import { lazy, number, string, ValidationError } from "yup"

/** @note Export for unit tests */
export const MessageTimestampSchema = lazy((value) => {
  switch (typeof value) {
    case 'number':
      return number().required().positive()
    case 'string':
      return string().required()
    default:
      throw new ValidationError("Timestamp does not satisfy MessageTimestampSchema")
  }
})
