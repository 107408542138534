import { useContext } from "react"
import { useParams } from "react-router-dom"
import { FaMountain } from "react-icons/fa6"

import { FeedComponentDto } from "sds"

import config from "../../../config"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { ListPage } from "../-components/ListPage"
import FeedComponentItem from "../-components/FeedComponentItem"

import '../RecipeManagement.css'

export default function FeedComponents() {
  const params = useParams()
  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const feedComponents = UserDataContextHelper.findFeedComponents(org)

  return <>
    <ListPage
      _className="feed-components"
      icon={<FaMountain className="w-5 _h-3.5_ relativ text-[#3E9E29]" />}
      title="Komponenten"
      items={feedComponents.sort((a, b) => a.name < b.name ? -1 : 1)}
      maxNumItems={config.limits.numberOfFeedComponents}
      renderFunc={(item: FeedComponentDto, index: number) => <FeedComponentItem key={item.id} org={org} item={item} />}
    />

    {/* <small>
      * Die Icons "mountains" (Plural), "sliders-up", "eye (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.<br />
      * Die Schrift der Namen ist hier kleiner (als bei Ausladestellen und Rezepten), verm. weil das Bild kleiner ist.
    </small> */}
  </>
}
