import { PropsWithChildren } from "react"
import { Card } from "@aws-amplify/ui-react"

type SecondaryListItemProps = PropsWithChildren & {
  title: string
  _className?: string
}
export default function SecondaryListItem({ title, _className, children }: SecondaryListItemProps) {
  return (
    <Card className={`!p-2 ${_className}`}
    style={{
      // fontFamily:"Inter Variable"
    }}>
      <div className="font-semibold text-[16px]">{title}</div>
      <div className="text-[12px] details">
        {children}
      </div>
    </Card>
  )
}
