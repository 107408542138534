import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, isRouteErrorResponse, RouteObject, RouterProvider, useRouteError } from 'react-router-dom'

import * as serviceWorker from './serviceWorker'

import * as App from './App'
import * as FeedingReportsApi from './api/FeedingReportsApi'
import * as FeedingStatsApi from './api/FeedingStatsApi'
import HomepageRedirect from './routes/HomepageRedirect'
import Login from './routes/Login'
// import ModemsPage from './routes/ModemsPage'
// import ModemDetails from './components/modem-details/ModemDetails'
import VehiclePage from './routes/VehiclePage'
import * as VehiclesListPage from './routes/vehicles/VehiclesListPage'
import VehicleOverview from './components/vehicle-overview/VehicleOverview'
import VehicleCanTraces from './components/vehicle-debug/VehicleCanTraces'
import VehicleDebug from './components/vehicle-debug/VehicleDebug'
import VehicleReportPage from './routes/VehicleReportPage'
import TimeseriesPage from './routes/TimeseriesPage'

import Orgs from './routes/orgs/Orgs'
import Recipes from './routes/orgs/recipes/Recipes'
import Recipe from './routes/orgs/recipes/Recipe'
import RecipeCreateEdit from './routes/orgs/recipes/RecipeCreateEdit'
import FeedComponents from './routes/orgs/feed-components/FeedComponents'
import FeedComponent from './routes/orgs/feed-components/FeedComponent'
import FeedComponentCreateEdit from './routes/orgs/feed-components/FeedComponentCreateEdit'
import AnimalGroups from './routes/orgs/animal-groups/AnimalGroups'
import AnimalGroup from './routes/orgs/animal-groups/AnimalGroup'
import AnimalGroupCreateEdit from './routes/orgs/animal-groups/AnimalGroupCreateEdit'
import OrgDashboard from './routes/orgs/OrgDashboard'
import OrgSettings from './routes/orgs/OrgSettings'
import Feedings from './routes/orgs/stats/Feedings'
import FeedingDetails from './routes/orgs/stats/FeedingDetails'
import GenauigkeitOverall from './routes/orgs/stats/GenauigkeitOverall'
import VerbrauchOverall from './routes/orgs/stats/VerbrauchOverall'
import StatsSettings from './routes/orgs/stats/StatsSettings'

const orgsRoutes: RouteObject = {
  path: "orgs",
  children: [
    {
      index: true,
      element: <Orgs />
    },
    {
      path: ":orgId",
      children: [
        {
          index: true,
          element: <OrgDashboard />
        },
        {
          path: "edit",
          element: <OrgSettings />
        },
        {
          path: "recipes",
          children: [
            {
              index: true,
              element: <Recipes />
            },
            {
              path: "new",
              element: <RecipeCreateEdit />
            },
            {
              path: ":recipeId",
              children: [
                {
                  index: true,
                  element: <Recipe />
                },
                {
                  path: "edit",
                  element: <RecipeCreateEdit />
                }
              ]
            }
          ]
        },
        {
          path: "feed-components",
          children: [
            {
              index: true,
              element: <FeedComponents />
            },
            {
              path: "new",
              element: <FeedComponentCreateEdit />
            },
            {
              path: ":feedComponentId",
              children: [
                {
                  index: true,
                  element: <FeedComponent />,
                  loader: FeedingStatsApi.loader,
                },
                { path: "edit", element: <FeedComponentCreateEdit /> }
              ]
            }
          ]
        },
        {
          path: "animal-groups",
          children: [
            {
              index: true,
              element: <AnimalGroups />
            },
            {
              path: "new",
              element: <AnimalGroupCreateEdit />
            },
            {
              path: ":animalGroupId",
              children: [
                {
                  index: true,
                  element: <AnimalGroup />,
                  loader: FeedingStatsApi.loader,
                },
                {
                  path: "edit",
                  element: <AnimalGroupCreateEdit />
                }
              ]
            }
          ]
        },
        {
          path: "stats",
          children: [
            {
              path: "feedings",
              children: [
                {
                  index: true,
                  element: <Feedings />,
                  loader: FeedingStatsApi.loader,
                },
                {
                  path: ":feedingReportId",
                  element: <FeedingDetails />,
                  loader: FeedingReportsApi.loader,
                }
              ]
            },
            {
              path: "accuracy",
              element: <GenauigkeitOverall />,
              loader: FeedingStatsApi.loader,
            },
            {
              path: "v",
              element: <VerbrauchOverall />,
              loader: FeedingStatsApi.loader,
            },
            {
              path: "settings",
              element: <StatsSettings />
            },
          ]
        }
      ]
    }
  ]
}
const vehiclesRoutes: RouteObject = {
  path: "vehicles",
  children: [
    {
      index: true,
      element: <VehiclesListPage.Component />,
      loader: VehiclesListPage.loader,
      // TODO: Create a generic Error Component, or Error Page
      // if (error) {
      //   console.error('Fehler in VehiclesList')
      //   return <div>Fehler beim Laden der Daten: {error}</div>
      // }
      // if (!data || !Array.isArray(data) || data.length < 1) {
      //   return <div>Fehler beim Laden der Daten: Keine Daten gefunden</div>
      // }
      // TODO: Create path-less routes that catch errors in blocks of the UI
      errorElement: <div>Error in VehiclesListPage</div>
    },
    {
      path: ":vin",
      element: <VehiclePage />,
      children: [
        {
          index: true,
          element: <VehicleOverview />
        },
        {
          path: "can-traces",
          children: [
            {
              index: true,
              element: <VehicleCanTraces />
            }
          ]
        },
        {
          path: "debug",
          children: [
            {
              index: true,
              element: <VehicleDebug />
            }
          ]
        },
        {
          path: "report",
          children: [
            {
              index: true,
              element: <VehicleReportPage />
            }
          ]
        },
        {
          path: "history/:pgn/:sourceAddress/:spn/",
          element: <TimeseriesPage mode='pgn' />
        },
        {
          path: "history/dtcs/",
          element: <TimeseriesPage mode='dtcs' />
        }
      ]
    }
  ]
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App.Component />,
    errorElement: <ErrorBoundary />,
    id: "root",
    loader: App.loader,
    children: [
      {
        index: true,
        element: <HomepageRedirect />,
      },
      {
        path: "/login",
        element: <Login />
      },
      orgsRoutes,
      vehiclesRoutes,
      {
        path: "*",
        element: <main style={{ padding: '1rem' }}>
          <p>Hier gibt's nichts zu sehen!</p>
        </main>
      }
      // Alternative:
      // {
      //   path: "*",
      //   element: <Navigate to="/" replace />
      // }
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return <>
    <div>
      <h1>
        Strautmann SDS
      </h1>
    </div>
    <div>
      Es ist ein Fehler aufgetreten.
      Sie können versuchen, alle Daten neu zu laden, oder sich neu anzumelden.
    </div>
    <div>Daten neu laden</div>
    <div>
      <a href="/login">Abmelden</a>
    </div>
    <div className='mt-20 text-xs text-gray-300'>
      {isRouteErrorResponse(error) && (
        <>
          <div>{error.status}</div>
          <div>{error.data}</div>
          {error.data?.message && <div>{error.data.message}</div>}
        </>
      )}
      {
        // @ts-ignore
        error?.message && <div>{error.message}</div>
      }
    </div>
  </>
}
