import { useLoaderData } from "react-router-dom"
import { ChartOptions } from "chart.js"
import 'chartjs-adapter-luxon'

import { FeedComponentDto } from "sds"
import { getAnnotationSettings, prepareAccuracyDatasets } from "./ChartUtils"
import GenauigkeitSingleChart from "./GenauigkeitSingleChart"
import SecondaryListItem from "./SecondaryListItem"

type GenauigkeitPerFeedComponentProps = {
  _className?: string
  feedComponent: FeedComponentDto
}
export default function GenauigkeitPerFeedComponent({ feedComponent, _className }: GenauigkeitPerFeedComponentProps) {
  const feedingStats = useLoaderData() as any
  const data = (feedingStats.AccuracyPerComponent as {
    [feedComponentId: string]: {
      feedComponentId: string,
      label: string,
      xData: string[],
      yData: number[],
    }
  })[feedComponent.id]

  if (!data) {
    return (
      <SecondaryListItem title="Genauigkeit" _className={_className}>
        {feedComponent.name} wurde in keinem Rezept verwendet.
      </SecondaryListItem>
    )
  }

  const datasetComponent = prepareAccuracyDatasets({ [feedComponent.id]: data })

  const annotationSettings = getAnnotationSettings()
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          boxYellowUpper: {
            type: 'box',
            yMin: annotationSettings.yellowUpper.min,
            yMax: annotationSettings.yellowUpper.max,
            backgroundColor: annotationSettings.yellowUpper.color,
            borderWidth: 0,
          },
          boxGreen: {
            type: 'box',
            yMin: annotationSettings.green.min,
            yMax: annotationSettings.green.max,
            backgroundColor: annotationSettings.green.color,
            borderWidth: 0,
          },
          boxYellowLower: {
            type: 'box',
            yMin: annotationSettings.yellowLower.min,
            yMax: annotationSettings.yellowLower.max,
            backgroundColor: annotationSettings.yellowLower.color,
            borderWidth: 0,
          },
        }
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getDate()).slice(-2)
              + '.'
              + ('0' + (dt.getMonth() + 1)).slice(-2)
              + '. '
            // + ('0' + dt.getHours()).slice(-2)
            // + ':'
            // + ('0' + dt.getMinutes()).slice(-2)
          }
        },
      }
    }
  }

  return (
    <SecondaryListItem title="Genauigkeit" _className={`chart-container ${_className}`}>
      <GenauigkeitSingleChart _className="!w-full" series={datasetComponent} chartOptions={options} />
    </SecondaryListItem>
  )
}
