/** Basic parsing of a HTTP query string.
 * @param queryString The query string. May contain a '?' as first character. Characters after a '#' are NOT removed.
 * @returns Key-value pairs of the query string parameters. If a key exists multiple times, the last value is returned.
 */
export function parseQueryString(queryString: string): Map<string, string> {
  var query = new Map<string, string>()
  var pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&')
  for (var i = 0; i < pairs.length; i++) {
    const kvPair = pairs[i].split('=')
    const key = decodeURIComponent(kvPair[0])
    const value = decodeURIComponent(kvPair[1] || '')
    query.set(key, value)
  }
  return query
}

/** Determines if the parameter is numeric.
 * 
 * @returns true, iff the parameter is a number or a string containing only a number, e.g. "1337", "133.7", etc.
 * @returns false, otherwise
 */
export const isNumeric = (maybeNumber: any): boolean => {
  if (typeof maybeNumber === "number") {
    return true
  }
  if (typeof maybeNumber === "string") {
    // catch strings that start with a number, but contain non-numeric characters later
    if (isNaN(+maybeNumber)) {
      return false
    }
    // catch empty and whitespace strings
    if (isNaN(parseFloat(maybeNumber))) {
      return false
    }
    return true
  }
  // any other type
  return false
}

/** Creates a random alphanumeric ID. */
export function makeId(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

/** Converts a number to hex */
export const toHex = (n: number, maxLen: number) => {
  return '0x' + n.toString(16)
    .toUpperCase()
    .padStart(maxLen, '0')
}
