import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { makeId } from './Utils'

/**
 * Stores a message ({@linkcode content}) to a dead letter queue
 * @deprecated
 * 
 * @param bucket The bucket name
 * @param keyPrefix The prefix before "dead-letter-queue/"
 * @param content The content of the message
 * @param timestamp The timestamp corresponding to the message
 */
export const storeToDeadLetterQueue_v1 = async (bucket: string, keyPrefix: string, content: string, timestamp: Date) => {
    const dlqKey = getDeadLetterQueueKey(keyPrefix, timestamp)
    return storePayloadInS3_v1(bucket, dlqKey, content)
}

/**
 * Stores a message ({@linkcode content}) to a file in S3.
 * @deprecated
 * 
 * @param bucket The bucket name
 * @param key The full key of the file
 * @param content The content of the message
 */
export const storePayloadInS3_v1 = async (bucket: string, key: string, content: string) => {
    console.info(`Put object to ${bucket} / ${key}`)
    const s3client = new S3Client({ region: 'eu-central-1' })
    const command = new PutObjectCommand({
        Bucket: bucket,
        Key: key,
        Body: content,
        BucketKeyEnabled: true,
        ServerSideEncryption: 'AES256'
    })
    return s3client.send(command)
}

/**
 * Stores a message ({@linkcode content}) to a dead letter queue
 * 
 * @param keyPrefix The prefix before "dead-letter-queue/"
 * @param timestamp The timestamp corresponding to the message
 * @param extension The file extension (default: ".txt")
 */
export const getDeadLetterQueueKey = (keyPrefix: string, timestamp: Date, id?: string, extension: string = ".txt") => {
    const unixTs = timestamp.getTime()
    if (!id) {
        id = makeId(5)
    }
    const dlqKey = `${keyPrefix}dead-letter-queue/${unixTs}-${makeId(5)}${extension}`
    return dlqKey
}

/**
 * Stores a message ({@linkcode content}) to a dead letter queue
 * 
 * @param bucket The bucket name
 * @param keyPrefix The prefix before "dead-letter-queue/"
 * @param content The content of the message
 * @param timestamp The timestamp corresponding to the message
 * @param s3client The S3Client
 */
export const storeToDeadLetterQueue = async (bucket: string, keyPrefix: string, content: string, timestamp: Date, s3client: S3Client) => {
    const dlqKey = getDeadLetterQueueKey(keyPrefix, timestamp)
    return storePayloadInS3(bucket, dlqKey, content, s3client)
}

/**
 * Stores a message ({@linkcode content}) to a file in S3.
 * 
 * @param bucket The bucket name
 * @param key The full key of the file
 * @param content The content of the message
 * @param s3client The S3Client
 */
export const storePayloadInS3 = async (bucket: string, key: string, content: string, s3client: S3Client) => {
    console.info(`Put object to ${bucket} / ${key}`)
    const command = new PutObjectCommand({
        Bucket: bucket,
        Key: key,
        Body: content,
        BucketKeyEnabled: true,
        ServerSideEncryption: 'AES256'
    })
    return s3client.send(command)
}
