import { Flex, Image, useTheme } from "@aws-amplify/ui-react"

export default function AuthenticatorHeader() {
  const { tokens } = useTheme()
  const env = process.env.NODE_ENV.toLowerCase()
  const isProdEnv = env.startsWith('prod')
  const isDevEnv = env.startsWith('dev')

  return (
    <Flex justifyContent="center" alignItems="center" alignContent="center" className="!bg-gradient-to-b from-[#25c401] to-[#498e38] !mb-2 !shadow-md">
      <Image
        alt="Strautmann Data System"
        src="/logo-strautmann.svg"
        padding={tokens.space.small}
      />
      <div style={{
        fontSize: '47px',
        fontWeight: 'bold'
      }}
        className="text-white">
        SDS
        {isProdEnv
          ? ''
          : ` /${isDevEnv ? 'dev' : env}`
        }
      </div>
    </Flex>
  )
}