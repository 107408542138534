import { LoaderFunction } from "react-router-dom"
import { getCurrentUser } from "aws-amplify/auth"
import { Cache } from 'aws-amplify/utils'

import { isProd } from "../config"

import { fetchFromApi, getCacheKey } from "./utils"

// 10 minutes
const EXPIRES_IN_MS = 10 * 60 * 1000

const getLatestFeedingStats = async (orgId: string): Promise<any | null> => {
  console.log("> getLatestFeedingStats")
  const apiName = 'frontendApi'
  const path = `/feeding-stats/${orgId}/latest`
  const cacheKey = getCacheKey(apiName, path)

  // Only load if the user is signed in.
  try {
    const currentUser = await getCurrentUser()
    // console.log("  got signed in user:", currentUser)
  } catch (err) {
    // console.log("  cannot load FeedingStats data: No signed in user.")
    if (!isProd) {
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
    // console.log("  no signed in user:", err)
    // console.log("❌ getLatestFeedingStats: RETURN NULL")
    return null
  }

  try {
    // console.log('  try to get item from cache')
    console.warn('  remove item from cache') // DBG
    await Cache.removeItem(cacheKey) // DBG
    let data = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path)
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getLatestFeedingStats: data")
    return await data
  }
  catch (err) {
    console.error('Error fetching FeedingStats data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getLatestFeedingStats: throw")
    throw err
  }
}

const loadFeedingStatsData = async (orgId: string): Promise<any | undefined> => {
  console.log("> loadFeedingStatsData")
  const feedingStatsData = await getLatestFeedingStats(orgId)
  if (!feedingStatsData) {
    console.log("  😕 got no FeedingStats data")
    return undefined
  }
  // console.log("  🎉 got FeedingStats data")
  console.log("< loadFeedingStatsData")
  return feedingStatsData
}

export const loader: LoaderFunction = async ({ params }): Promise<any | null> => {
  console.log("> FeedingStatsApi.loader")
  if (!params.orgId) {
    console.log("  param orgId is missing")
    return null
  }
  const feedingStatsData = await loadFeedingStatsData(params.orgId)
  if (!feedingStatsData) {
    return null
  }
  return feedingStatsData
}
