import { get } from 'aws-amplify/api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Chart as ChartJS, CategoryScale, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip } from "chart.js"
import 'chartjs-adapter-luxon'
import { Line } from "react-chartjs-2"
import DateTimePicker from "react-tailwindcss-datetimepicker"

import { toHex, toLocalStringDe } from "strtmn"
import { DtcEntry } from '../../../../lib/j1939/src'

import { currentSession } from '../Utils.AmplifyAuth';
import VehiclePageHeader from '../components/VehiclePageHeader'
import { DtcTable } from '../components/vehicle-debug/Fehlerliste'
import { isDev } from '../config'

import './TimeseriesPage.css'

ChartJS.register(
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
)

type TimeseriesPageMode = 'pgn' | 'dtcs' | 'presence' | 'location'
type TimeseriesId = {
  vin: string
  pgn: number
  sourceAddress: number
  spn: string
}
/** @note Type also exists in backend (fe-api / telemetry) */
type NumericTimeseriesEntry = {
  Timestamp: number
  Value: string | number | any
}
/** @note Type also exists in backend (fe-api / telemetry) */
type DtcTimeseriesEntry = {
  FailureMode: number
  FailureModeName: string
  IsActive: boolean
  MalfunctionLamp: number
  MalfunctionLampStatusName: string
  Occurence: number
  ProtectLamp: number
  ProtectLampStatusName: string
  SourceAddress: number | string
  SPN: number | string
  StopLamp: number
  StopLampStatusName: string
  Timestamp: number
  WarningLamp: number
  WarningLampStatusName: string
}


type TimeseriesPageHeaderProps = TimeseriesId & {
  mode: TimeseriesPageMode
  begin: Date
  end: Date
}
const TimeseriesPageHeader = ({ mode, vin, pgn, sourceAddress, spn, begin, end, ...props }: TimeseriesPageHeaderProps) => {
  return (
    <table>
      <tbody>
        {/* <div>
        VIN: <Link to={getLinkToVehicleDebug({ vin: vin })}>{vin}</Link>
      </div> */}
        {(mode === 'dtcs')
          ? <tr>
            <td>PGN:</td>
            <td>DTCs</td>
          </tr>
          : <>
            <tr>
              <td>PGN:</td>
              <td>{toHex(pgn, 4)}</td>
            </tr>
            <tr>
              <td>Source Address:</td>
              <td>{toHex(sourceAddress, 4)}</td>
            </tr>
            <tr>
              <td>SPN:</td>
              <td>{spn}</td>
            </tr>
          </>
        }
        <tr>
          <td>Zeitraum:</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )
}

type TimeseriesPageChartProps = {
  dataWithMeta: TimeseriesId & {
    [meta: string]: any
    data: NumericTimeseriesEntry[]
  }
}
const TimeseriesPageChart = ({ dataWithMeta, ...props }: TimeseriesPageChartProps) => {
  const barThickness = 6
  // const barPercentage = 8
  const title = `${dataWithMeta.spn}`

  const rows = dataWithMeta.data
  console.log('dataWithMeta', dataWithMeta)

  const xLabels = rows.map((row) => {
    const dtUtc = new Date(row.Timestamp).toISOString()
    return dtUtc
  })
  console.log('xLabels', xLabels)

  const tsValues = rows.map(row => {
    return Number(row.Value)
  })
  console.log('tsValues', tsValues)

  const cdata = {
    labels: xLabels,
    datasets: [
      {
        label: title,
        data: tsValues,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)'
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `${dataWithMeta.begin} -- ${dataWithMeta.end}`,
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        // suggestedMin: dataWithMeta.begin,
        min: dataWithMeta.begin,
        // suggestedMax: dataWithMeta.end,
        max: dataWithMeta.end,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getDate()).slice(-2)
              + '.'
              + ('0' + (dt.getMonth() + 1)).slice(-2)
              + '. '
              + ('0' + dt.getHours()).slice(-2)
              + ':'
              + ('0' + dt.getMinutes()).slice(-2)
          }
        },
      }
    }
  }

  return <div>
    <div>
      {dataWithMeta.data.length} Werte in der Zeitreihe
      {dataWithMeta.maybeMoreItems
        ? <>{' '}(vermutlich nicht vollständig)</>
        : <></>}
    </div>

    <Line className='timeseries-chart' data={cdata} options={options} />
  </div>
}

type TimeseriesPageListProps = {
  dataWithMeta: TimeseriesId & {
    [meta: string]: any
    data: DtcTimeseriesEntry[]
  }
}
const TimeseriesPageList = ({ dataWithMeta, ...props }: TimeseriesPageListProps) => {
  const rows = dataWithMeta.data as unknown as DtcEntry[]
  console.log('# dtcs:', rows.length)
  return <DtcTable dtcsSorted={rows} />
}



type TimeseriesPageProps = {
  mode: TimeseriesPageMode
}
export default function TimeseriesPage({ mode, ...props }: TimeseriesPageProps) {
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [data, setData] = useState<any | undefined>(undefined)

  const [vin, setVin] = useState<string>('')
  const [pgn, setPgn] = useState<number>(-1)
  const [sourceAddress, setSourceAddress] = useState<number>(-1)
  const [spn, setSpn] = useState<string>('')

  const now = new Date()
  const defaultDaysBack = 1
  const defaultBegin = new Date(now.getTime() - defaultDaysBack * 24 * 3600 * 1000)
  const defaultEnd = now
  const [begin, setBegin] = useState<Date>(defaultBegin)
  const [end, setEnd] = useState<Date>(defaultEnd)

  const [selectedRange, setSelectedRange] = useState({
    start: defaultBegin,
    end: defaultEnd,
  })

  function handleApply(startDate: Date, endDate: Date) {
    setSelectedRange({ start: startDate, end: endDate })
    setBegin(startDate)
    setEnd(endDate)
  }

  useEffect(() => {
    // This effect parses the params into state
    setVin(params.vin!)
    setPgn((mode === 'dtcs')
      ? -1
      : Number(params.pgn!))
    setSourceAddress((mode === 'dtcs')
      ? -1
      : Number(params.sourceAddress!))
    setSpn((mode === 'dtcs')
      ? ''
      : params.spn!)
  }, [params, mode])

  useEffect(() => {
    // This effect uses state to load data
    const getData = async () => {
      const tokens = await currentSession()
      const apiName = 'frontendApi'
      const path = (mode === 'dtcs')
        ? `/timeseries/${vin}/dtcs?begin=${begin!.toISOString()}&end=${end!.toISOString()}`
        : `/timeseries/${vin}/${pgn}/${sourceAddress}/${spn}?begin=${begin!.toISOString()}&end=${end!.toISOString()}`
      // console.log('path', path)
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${tokens?.idToken}`
          }
        }
        console.debug(`Sending API call`)
        const restOperation = get({ apiName, path, options })
        const response = await (await restOperation.response).body.json()
        // console.log(response)

        setData(response)
        setError(undefined)
      }
      catch (err) {
        console.error('Error fetching data from api')
        if (isDev) {
          console.error(`Tried to fetch Timeseries data from ${apiName} :: ${path}`)
        }
        let errMsg: string
        /** @ts-ignore */
        errMsg = err!.response!.data!.message!
        if (!errMsg) {
          errMsg = (err instanceof Error)
            ? err.message
            : String(err)
        }
        console.error(errMsg)
        setData(undefined)
        setError(errMsg)
      }
      finally {
        setLoading(false)
      }
    }

    if (vin && vin !== 'never-set') {
      getData()
    }
  }, [mode, vin, pgn, sourceAddress, spn, begin, end])

  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return 'development' === process.env.NODE_ENV
      ? <div>Fehler beim Laden der Daten: {error}</div>
      : <div>Fehler beim Laden der Daten.</div>
  }
  if (!data) {
    return <div>Fehler beim Laden der Daten: Keine Daten gefunden</div>
  }

  const startOfYesterday = new Date()
  startOfYesterday.setDate(startOfYesterday.getDate() - 1)
  startOfYesterday.setHours(0, 0, 0, 0)
  const endOfYesterday = new Date(startOfYesterday)
  endOfYesterday.setDate(endOfYesterday.getDate() + 1)
  endOfYesterday.setSeconds(endOfYesterday.getSeconds() - 1)

  return (
    <main className='timeseries-page'>
      <VehiclePageHeader vin={data.vin!} />

      <h2>Zeitreihe</h2>

      <TimeseriesPageHeader mode={mode} vin={params.vin!} pgn={pgn} sourceAddress={sourceAddress} spn={spn} begin={begin!} end={end!} />

      <DateTimePicker
        // locale={}
        ranges={{
          "Letzte 24 Std.": [defaultBegin, defaultEnd],
          "Gestern": [startOfYesterday, endOfYesterday],
          "Letzte 7 Tage": [
            new Date(now.getTime() - 7 * 24 * 3600 * 1000),
            defaultEnd,
          ],
          "Letzte 30 Tage": [
            new Date(now.getTime() - 30 * 24 * 3600 * 1000),
            defaultEnd,
          ],
        }}
        start={selectedRange.start}
        end={selectedRange.end}
        applyCallback={handleApply}
        locale={{
          cancel: "Abbrechen",
          apply: "Übernehmen",
          close: "Schließen",
          fromDate: "Start",
          toDate: "Ende",
          format: "dd.MM.yyyy HH:mm", // See: https://date-fns.org/v2.16.1/docs/format
        }}
        maxDate={now}
        years={[2024, now.getFullYear()]}
        smartMode={true}
        pastSearchFriendly={true}
        standalone={true}
      >
        <button type="button">{`${toLocalStringDe(selectedRange.start.toISOString())} -- ${toLocalStringDe(selectedRange.end.toISOString())}`}</button>
      </DateTimePicker>

      {
        0 === data.data.length
          ? <>Keine Datenpunkte im Zeitraum.</>
          : <>{mode === 'dtcs'
            ? <TimeseriesPageList dataWithMeta={data} />
            : <TimeseriesPageChart dataWithMeta={data} />
          }</>
      }
    </main >
  )
}
