interface ConnectivityIndicatorProps {
  name: string
  isConnected: boolean
  isDisabled: boolean
}

export default function ConnectivityIndicator({ name, isConnected, isDisabled }: ConnectivityIndicatorProps) {
  const borderColor = (isDisabled ? 'DarkGray' : isConnected ? 'Teal' : 'DarkRed')
  const bgColor = (isDisabled ? 'LightGray' : isConnected ? 'YellowGreen' : 'LightCoral')

  return (
    <span title={name} style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-flex',
      textAlign: 'center',
      lineHeight: '1.2em',
      height: '1.2em',
      width: '1.2em',
      borderRadius: '50%',
      border: '2px solid ' + borderColor,
      backgroundColor: bgColor,
    }}>{name[0]}</span>
  )
}
