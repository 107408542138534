/** Failure Mode Identifier values.
 * 
 * Will be serialized as number [0..31].
 * Source: https://help.codesys.com/webapp/hCRKLh0SL1tIbbCyLr6WGnaMt18%2FFMI;product=IoDrvJ1939;version=3.5.17.0
 * @todo: Please check if values are correct.
 */
export enum FmiValues {
    DATA_ABOVE_NORMAL_MOST_SEVERE_LEVEL = 0,
    DATA_BELOW_NORMAL_MOST_SEVERE_LEVEL = 1,
    DATA_ERRATIC = 2,
    VOLTAGE_ABOVE_NORMAL = 3,
    VOLTAGE_BELOW_NORMAL = 4,
    CURRENT_BELOW_NORMAL = 5,
    CURRENT_ABOVE_NORMAL = 6,
    MECHANICAL_SYSTEM_NOT_RESPONDING = 7,
    ABNORMAL_FREQUENCY = 8,
    ABNORMAL_UPDATE_RATE = 9,
    ABNORMAL_RATE_OF_CHANGE = 10,
    ROOT_CAUSE_NOT_KNOWN = 11,
    BAD_INTELLIGENT_DEVICE = 12,
    OUT_OF_CALIBRATION = 13,
    SPECIAL_INSTRUCTIONS = 14,
    DATA_ABOVE_NORMAL_LEAST_SEVERE_LEVEL = 15,
    DATA_ABOVE_NORMAL_MODERATELY_SEVERE_LEVEL = 16,
    DATA_BELOW_NORMAL_LEAST_SEVERE_LEVEL = 17,
    DATA_BELOW_NORMAL_MODERATELY_SEVERE_LEVEL = 18,
    RECEIVED_NETWORK_DATA_IN_ERROR = 19,
    DATA_DRIFTED_HIGH = 20,
    DATA_DRIFTED_LOW = 21,
    CONDITION_EXISTS = 31
}
