import { get } from 'aws-amplify/api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Chart as ChartJS, CategoryScale, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip } from "chart.js"
import 'chartjs-adapter-luxon'
import { Line } from "react-chartjs-2"

import { currentSession } from '../Utils.AmplifyAuth'
import VehiclePageHeader from '../components/VehiclePageHeader'

import './VehicleReportPage.css'

ChartJS.register(
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
)

type TimeseriesId = {
  vin: string
  pgn: number
  sourceAddress: number
  spn: string
}
const getTimeseriesIdSlug = (timeseriesId: TimeseriesId): string => {
  return [timeseriesId.vin, timeseriesId.sourceAddress, timeseriesId.pgn, timeseriesId.spn].join('#')
}

type TimeseriesChartConfig = {
  name?: string
  min?: number
  max?: number
  unit?: string
}
type TimeseriesConfig = TimeseriesId & {
  chart: TimeseriesChartConfig
}

type TimeseriesWithMeta = TimeseriesId & {
  [meta: string]: any
  data: NumericTimeseriesEntry[]
  chart?: TimeseriesChartConfig
}

/** @note Type also exists in backend (fe-api / telemetry) */
type NumericTimeseriesEntry = {
  Timestamp: number
  Value: string | number | any
}


const sleep = (ms: number) => new Promise(rs => setTimeout(rs, ms))
const rate_limit = 300
let requestsInFlight = 0
const maxInFlight = 5

type TimeseriesMultiChartLoaderProps = {
  timeseriesCfgs: TimeseriesConfig[]
  begin: Date
  end: Date
}
const TimeseriesMultiChartLoader = ({ timeseriesCfgs, begin, end, ...props }: TimeseriesMultiChartLoaderProps) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [data, setData] = useState<TimeseriesWithMeta[] | undefined>(undefined)

  useEffect(() => {
    /** This wrapper applies rate-limiting, and enables retries for API calls
     * @todo
     *   - extract into a util class.
     *   - the rate-limiting heuristic is very crude. Using a request queue would probably be a better alternative.
     *   - Links for inspiration
     *     - https://stackoverflow.com/questions/65850544/rate-limit-the-number-of-request-made-from-react-client-to-api
     *     - https://stackoverflow.com/questions/40639432/what-is-the-best-way-to-limit-concurrency-when-using-es6s-promise-all
     *     - https://stackoverflow.com/questions/38778723/limit-concurrency-of-pending-promises
     *     - https://gist.github.com/alexpsi/43dd7fd4d6a263c7485326b843677740
     *     - https://github.com/jiaju-yang/promise-kue
     */
    const loadDataRetryWrapper = async (timeseriesCfg: TimeseriesConfig, numRetriesLeft: number, retryCount: number = 0): Promise<TimeseriesWithMeta> => {
      console.log(new Date().getTime(), '> loadDataRetryWrapper')
      if (numRetriesLeft <= 0) {
        throw new RangeError("numRetriesLeft must be >= 0.")
      }
      let delay = 0
      if (requestsInFlight >= maxInFlight) {
        delay = Math.ceil(requestsInFlight / maxInFlight) * rate_limit
      }
      requestsInFlight++
      const myRequestsInFlight = requestsInFlight
      console.log('delay =', delay)
      await sleep(delay)
      console.log(new Date().getTime(), ': loading data', '; myRequestsInFlight = ', myRequestsInFlight, '; delay = ', delay, '; retryCount = ', retryCount)

      try {
        return await loadData(timeseriesCfg)
      } catch (err) {
        if (numRetriesLeft > 1) {
          return await loadDataRetryWrapper(timeseriesCfg, numRetriesLeft - 1, retryCount + 1)
        }
        throw err
      } finally {
        requestsInFlight--
      }
    }

    const loadData = async (timeseriesCfg: TimeseriesConfig): Promise<TimeseriesWithMeta> => {
      console.log("> loadData(", timeseriesCfg.spn, ")")
      const tokens = await currentSession()
      const apiName = 'frontendApi'
      const path = `/timeseries/${timeseriesCfg.vin}/${timeseriesCfg.pgn}/${timeseriesCfg.sourceAddress}/${timeseriesCfg.spn}?begin=${begin!.toISOString()}&end=${end!.toISOString()}`
      // console.log('path', path)
      // try {
      const options = {
        headers: {
          Authorization: `Bearer ${tokens?.idToken!}`
        }
      }
      console.debug(`Sending API call`)
      const restOperation = get({ apiName, path: path + "&loadAllData=1", options })
      const response = await (await restOperation.response).body.json() as TimeseriesWithMeta
      // Round timestamps to seconds
      const dataMapped = response.data.map(elem => {
        const ts_ms = elem.Timestamp
        const ts_s = Math.floor(ts_ms / 1000)
        return {
          ...elem,
          Timestamp: ts_s
        }
      })
      console.log("< loadData(", timeseriesCfg.spn, ")")
      return {
        ...response,
        data: dataMapped,
        chart: timeseriesCfg.chart,
      }
      // }
      // catch{}
    }
    const loadMultiData = async () => {
      console.log("> loadMultiData")
      console.log("timeseriesCfgs:", JSON.stringify(timeseriesCfgs, null, 2))
      let datas: { [moar: string]: TimeseriesWithMeta } = {}
      const loadPromises = timeseriesCfgs.map(async (tsCfg) => {
        const tsSlug = getTimeseriesIdSlug(tsCfg)
        try {
          datas[tsSlug] = await loadDataRetryWrapper(tsCfg, 3)
          if (!datas[tsSlug] || 0 === datas[tsSlug].data.length) {
            console.warn(`Empty series ${tsSlug}`)
          }
        } catch (err) {
          /** @todo: error handling */
          console.warn(`Error loading series ${tsSlug}`)
          throw err
        }
        if (datas[tsSlug] && datas[tsSlug].data.length > 0) {
          console.log(tsSlug, ':', datas[tsSlug].data.length, 'data points')
          console.log(tsSlug, '[0]:', datas[tsSlug].data[0])
        }
      })
      await Promise.allSettled(loadPromises)
      console.log("datas.keys:", Object.keys(datas))
      // Use .map to preserve order
      const mapped: TimeseriesWithMeta[] = timeseriesCfgs.map((tsCfg): TimeseriesWithMeta => {
        const tsSlug = getTimeseriesIdSlug(tsCfg)
        return datas[tsSlug]
      })

      /** @debug */
      let unionOfTimestamps: any = {}
      let timestamps: any = {} // timestamps for each series
      timeseriesCfgs.forEach(tsCfg => {
        const tsSlug = getTimeseriesIdSlug(tsCfg)
        timestamps[tsSlug] = []
        if (!datas[tsSlug]) {
          return
        }
        datas[tsSlug].data.forEach((elem: NumericTimeseriesEntry) => {
          const ts_s = elem.Timestamp
          timestamps[tsSlug].push(ts_s)
          if (!unionOfTimestamps[ts_s]) {
            unionOfTimestamps[ts_s] = 1
          } else {
            unionOfTimestamps[ts_s]++
          }
        })
      })
      console.log("Timestamps with count 3:", Object.keys(unionOfTimestamps).filter(x => unionOfTimestamps[x] === 3).length)
      console.log("Timestamps with count 2:", Object.keys(unionOfTimestamps).filter(x => unionOfTimestamps[x] === 2).length)
      console.log("Timestamps with count 1:", Object.keys(unionOfTimestamps).filter(x => unionOfTimestamps[x] === 1))
      /**
       * @todo: timestamp rounding?
       * Round those TS with count < num_series to the nearest series-count timestamp
       * What if 2 series have the one ts, and 2 series have the other ts?
       * -> timestamps with count < num_series have to be valid rounding targets, too.
       * -> conflict resolution can be tricky
       */
      /** /@debug */

      setData(mapped)
      setLoading(false)
    }
    loadMultiData()
  }, [timeseriesCfgs, begin, end])

  if (loading) return <div>TMC::Loading...</div>
  if (error) return <div>TMC::Error: {error}</div>
  if (!data || 0 === data.length) return <div>TMC::Error: No data</div>
  /** @todo: render charts */
  return <TimeseriesMultiChart multiSeries={data} />
}

const CHART_COLORS: { [moar: string]: string } = {
  green: 'rgb(75, 192, 192)',
  orange: 'rgb(255, 159, 64)',
  purple: 'rgb(153, 102, 255)',
  red: 'rgb(255, 99, 132)',
  yellow: 'rgb(255, 205, 86)',
  blue: 'rgb(54, 162, 235)',
  grey: 'rgb(201, 203, 207)'
}

let BG_COLORS: { [moar: string]: string } = {}
Object.keys(CHART_COLORS).forEach(name => {
  BG_COLORS[name] = CHART_COLORS[name].replace(")", ", 0.5)")
})

type TimeseriesMultiChartProps = {
  multiSeries: TimeseriesWithMeta[]
}
const TimeseriesMultiChart = ({ multiSeries, ...props }: TimeseriesMultiChartProps) => {
  const barThickness = 6
  // const barPercentage = 8
  const borderColors = Object.values(CHART_COLORS)
  const backgroundColors = Object.values(BG_COLORS)
  const chartTitle = `${multiSeries.map(elem => elem.chart?.name ?? elem.spn).join(' // ')}`

  const seriesLengths = multiSeries.map(series => series.data.length)
  const maxSeriesLength = Math.max(...seriesLengths)
  const longestSeries = multiSeries.filter(series => series.data.length === maxSeriesLength)[0]

  let begin = longestSeries.begin
  let end = longestSeries.end
  let yMin = Infinity
  let yMax = -Infinity
  multiSeries.forEach(series => {
    if (series.begin < begin) {
      begin = series.begin
    }
    if (series.end > end) {
      end = series.end
    }
    if (series.chart?.min !== undefined && series.chart.min < yMin) {
      yMin = series.chart.min
    }
    if (series.chart?.max !== undefined && series.chart.max > yMax) {
      yMax = series.chart.max
    }
  })

  /**
   * @todo: this works only iff the first series has all relevant timestamps.
   * if it is sparse, the chart will break
   */
  const xLabels = longestSeries.data.map(row => {
    const dtUtc = new Date(row.Timestamp * 1000).toISOString()
    return dtUtc
  })
  console.log('xLabels', xLabels)

  let yScales: any = {}
  const datasets = multiSeries.map((series, index): any => {
    const backgroundColor = backgroundColors[index % backgroundColors.length]
    const borderColor = borderColors[index % borderColors.length]
    const yAxisID = 0 === index
      ? "y"
      : `y${index + 1}`
    yScales[yAxisID] = {
      type: 'linear',
      position: 'left',
      // stack: 'demo',
      // stackWeight: 2,
      border: {
        color: borderColor
      },
    }
    if (yMax > yMin) {
      yScales[yAxisID]['min'] = yMin
      yScales[yAxisID]['max'] = yMax
    }
    let seriesLabel = series.chart?.name ?? series.spn
    if (series.chart?.unit) {
      seriesLabel += ` [${series.chart.unit}]`
    }
    return {
      type: 'line',
      label: seriesLabel,
      data: series.data.map(elem => Number(elem.Value)),
      borderWidth: 1,
      barThickness: barThickness,
      // barPercentage: barPercentage,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      yAxisID: yAxisID
    }
  })

  const cdata = {
    labels: xLabels,
    datasets: datasets
  }
  // console.log('data[0-9]', datasets[0].data.slice(0, 10))

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `${chartTitle} *** ${begin} -- ${end}`,
        font: {
          size: 16
        },
      }
    },
    elements: {
      point: {
        radius: 0.5
      }
    },
    scales: {
      ...yScales,
      x: {
        bounds: 'data' as const,
        // suggestedMin: dataWithMeta.begin,
        min: begin,
        // suggestedMax: dataWithMeta.end,
        max: end,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getDate()).slice(-2)
              + '.'
              + ('0' + (dt.getMonth() + 1)).slice(-2)
              + '. '
              + ('0' + dt.getHours()).slice(-2)
              + ':'
              + ('0' + dt.getMinutes()).slice(-2)
          }
        },
      }
    }
  }

  return <div className='multi-line-chart'>
    {/* <div>
      {multiSeries.data.length} Werte in der Zeitreihe
      {multiSeries.maybeMoreItems
        ? <>{' '}(vermutlich nicht vollständig)</>
        : <></>}
    </div> */}

    <Line className='timeseries-chart' data={cdata} options={options} />
  </div>
}


export default function VehicleReportPage() {
  const params = useParams()
  const vin = params.vin!

  const numDays = 7
  const startOfSevenDaysAgo = new Date()
  startOfSevenDaysAgo.setDate(startOfSevenDaysAgo.getDate() - numDays)
  startOfSevenDaysAgo.setHours(0, 0, 0, 0)
  const startOfYesterday = new Date()
  startOfYesterday.setDate(startOfYesterday.getDate() - 1)
  startOfYesterday.setHours(0, 0, 0, 0)
  const endOfYesterday = new Date(startOfYesterday)
  endOfYesterday.setDate(endOfYesterday.getDate() + 1)
  endOfYesterday.setSeconds(endOfYesterday.getSeconds() - 1)

  const tsCfgBattTempLow: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA303,
    sourceAddress: 0x00F3,
    spn: "Low_Temperature",
    chart: {
      min: 0,
      max: 50,
      name: "Batt. Low Temp.",
      unit: '°C',
    }
  }
  const tsCfgBattTempHigh: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA303,
    sourceAddress: 0x00F3,
    spn: "High_Temperature",
    chart: {
      min: 0,
      max: 50,
      name: "Batt. High Temp.",
      unit: '°C',
    }
  }
  const tsCfgDcDcTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0x01E5,
    sourceAddress: 0x00F5,
    spn: "dcdc_internal_temp",
    chart: {
      min: 0,
      max: 100,
      name: "DcDc Temp.",
      unit: '°C',
    }
  }
  const tsCfgInvEfTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0x01D0,
    sourceAddress: 0x00EF,
    spn: "Inv1_IGBTTemp",
    chart: {
      min: 0,
      max: 100,
      name: "Inv. Hy. Temp.",
      unit: '°C',
    }
  }
  const tsCfgInvFfTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0x01D0,
    sourceAddress: 0x00FF,
    spn: "Inv1_IGBTTemp",
    chart: {
      min: 0,
      max: 100,
      name: "Inv. Misch. Temp.",
      unit: '°C',
    }
  }
  const tsCfgMotorEfTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0x01D0,
    sourceAddress: 0x00EF,
    spn: "Inv1_MotorTemp",
    chart: {
      min: 0,
      max: 140,
      name: "Motor Hy. Temp.",
      unit: '°C',
    }
  }
  const tsCfgMotorFfTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0x01D0,
    sourceAddress: 0x00FF,
    spn: "Inv1_MotorTemp",
    chart: {
      min: 0,
      max: 140,
      name: "Motor Misch. Temp.",
      unit: '°C',
    }
  }

  const tsCfgPackSoc: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA103,
    sourceAddress: 0x00F3,
    spn: "Pack_SOC",
    chart: {
      min: 0,
      max: 100,
      name: "Pack SoC",
      unit: '%',
    }
  }
  const tsCfgPackCurrent: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA103,
    sourceAddress: 0x00F3,
    spn: "Pack_Current",
    chart: {
      min: -20,
      max: 200,
      name: "Pack Current",
      unit: 'A',
    }
  }
  const tsCfgPackVoltage: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA103,
    sourceAddress: 0x00F3,
    spn: "Pack_Inst_Voltage",
    chart: {
      min: 320,
      max: 420,
      name: "Pack Voltage",
      unit: 'V',
    }
  }
  const tsCfgHighCellVoltage: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA303,
    sourceAddress: 0x00F3,
    spn: "High_Cell_Voltage",
    chart: {
      min: 2.8,
      max: 3.8,
      name: "High Cell Volt.",
      unit: 'V',
    }
  }
  const tsCfgLowCellVoltage: TimeseriesConfig = {
    vin: vin,
    pgn: 0xA303,
    sourceAddress: 0x00F3,
    spn: "Low_Cell_Voltage",
    chart: {
      min: 2.8,
      max: 3.8,
      name: "Low Cell Volt.",
      unit: 'V',
    }
  }

  const tsCfgHyOelTemp: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF79,
    sourceAddress: 0x0032,
    spn: "HyOelTemp",
    chart: {
    }
  }
  const tsCfgFraesDrAkt: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF7C,
    sourceAddress: 0x0032,
    spn: "FraesDrAkt",
    chart: {
    }
  }
  const tsCfgMischDrAkt: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF7B,
    sourceAddress: 0x0032,
    spn: "MischDrAkt",
    chart: {
    }
  }

  const tsCfgDruckSpeiseOel: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF84,
    sourceAddress: 0x0032,
    spn: "DruckSpeiseOel",
    chart: {
    }
  }

  const tsCfgWaagGewAkt: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF80,
    sourceAddress: 0x0032,
    spn: "WaagGewAkt",
    chart: {
    }
  }

  const tsCfgFahrGeschw: TimeseriesConfig = {
    vin: vin,
    pgn: 0xFF78,
    sourceAddress: 0x0032,
    spn: "FahrGeschw",
    chart: {
    }
  }

  return (
    <main className='timeseries-page'>
      <VehiclePageHeader vin={vin} />

      <h2>Bericht Temperaturen</h2>

      <div>
        Letzte {numDays} Tage ({startOfSevenDaysAgo.toISOString()} bis {endOfYesterday.toISOString()} UTC, DST, usw.)
      </div>

      {/**
        @notes
        `TimeseriesPageChart` zeigt eine Datenreihe an.
        `dataWithMeta` muss vin, spn, pgn, srcAddr haben, sowie data[]
        @todo:
        `TimeseriesPageChart` soll mehrere Datenreihen unterstützen -> dataWithMeta wird dataWithMeta[]
        Wrapper `TimeseriesPageChartLoader` erstellen, der sowas wie TimeseriesId[] erhält,
          dann die Datenreihen lädt
          und das Chart mit den Datenreihen anzeigt
        */}
      <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgBattTempLow,
        tsCfgBattTempHigh,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgHighCellVoltage,
        tsCfgLowCellVoltage,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgPackSoc,
        tsCfgPackCurrent,
        tsCfgPackVoltage,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgDcDcTemp,
        tsCfgInvEfTemp,
        tsCfgInvFfTemp,
        tsCfgMotorEfTemp,
        tsCfgMotorFfTemp,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      {/* <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgHyOelTemp,
        // tsCfgArbBetrModAkt
        tsCfgFraesDrAkt,
        tsCfgMischDrAkt,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      <TimeseriesMultiChartLoader timeseriesCfgs={[
        tsCfgDruckSpeiseOel,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} />

      <TimeseriesMultiChartLoader timeseriesCfgs={[
        // tsCfgMischDrAkt,
        // tsCfgMischDrehzAkt,
        tsCfgWaagGewAkt,
      ]} begin={startOfSevenDaysAgo} end={endOfYesterday} /> */}

    </main >
  )
}
