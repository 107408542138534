export const transformSecondsToMinutes = (sec: string | number): number => {
  const secAsNumber = Number(sec)
  if (!secAsNumber) {
    return 0
  }
  const minutes = Math.round(secAsNumber * 10 / 60) / 10 // rounding with max. 1 fractional digit
  return minutes
}

export const transformInputToSeconds = (min: string): number => {
  const minAsNumber = transformInputToNumber(min)
  const seconds = Math.round(minAsNumber * 60)
  return seconds
}

export const transformNumberToString = (num: string | number): string => {
  if (typeof (num) === "number") {
    return num.toLocaleString(undefined, {
      useGrouping: false // disable thousands separator
    })
  }
  return num.toString()
}

export const transformInputToInternational = (input: string): string => {
  const internationalDecimal = "."
  const lastPeriod = input.lastIndexOf(".")
  const lastComma = input.lastIndexOf(",")

  if (lastComma === -1) {
    // There's no comma. Assume it's an integer, or a decimal number in international format already.
    return input
  }

  // There is a comma. What is its role?

  if (lastPeriod === -1) {
    // There's no period. Assume the comma is the decimal separator.
    return input.replaceAll(",", internationalDecimal)
  }

  // There's both, period and comma. Assume the last one is the decimal separator.
  const decimalSep = lastPeriod > lastComma ? "." : ","
  const thousandsSep = "." === decimalSep ? "," : "."
  return input.replaceAll(thousandsSep, "").replaceAll(decimalSep, ".")
}

export const transformInputToNumber = (input: string): number => {
  const inputAsInternational = transformInputToInternational(input)
  const inputAsNumber = Number(inputAsInternational)
  if (!inputAsNumber) {
    return 0
  }
  return inputAsNumber
}
