import { useState } from "react"
import { Link, useParams, useRouteLoaderData } from "react-router-dom"
import { Button, ButtonGroup, Fieldset, Flex, Input, Radio, RadioGroupField, SelectField } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaXmark } from "react-icons/fa6"

import { Organization, UserDataDto } from "sds"

import config, { isProd } from "../../config"
import { UserDataHelper } from "../../api/userData"

import { imageHash } from "./-components/ListItem"

import './RecipeManagement.css'

const NUM_ORG_IMAGES = config.images.orgs.numImages
const IMAGE_HASH_SEED = config.images.orgs.imageHashSeed

export default function OrgSettings() {
  const params = useParams()
  const rootData = useRouteLoaderData("root") as UserDataDto
  // console.log("🧪 OrgDashboard / rootData:", rootData)
  const [org, setOrg] = useState<Organization | undefined>(undefined)

  if (!rootData) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (rootData) ...</>
  }

  let org_: Organization | undefined = undefined
  if (rootData) {
    org_ = UserDataHelper.findOrg(params.orgId!, rootData)
  }
  if (!org) {
    setOrg(org_)
  }
  if (!org_ || !org) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (org) ...</>
  }

  const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
  const imageSrc = org.name === "Andreas" || org.name === "Haggeney"
    ? `/images/orgs/hof-pixabay-1.jpg`
    : org.name === "Heiner" || org.name === "Große Börding"
      ? `/images/orgs/hof-pixabay-2.jpg`
      : `/images/orgs/hof-pixabay-${imageIdx}.jpg`
  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{org.name} &mdash; Einstellungen für den Betrieb</h2>
          </div>
        </div>

        <div className="details">
          <form>
            {/** @todo: {...register("fieldname")} */}
            <Fieldset legend="Name des Betriebs" variation="outlined" size="small">
              <Input type="text" size="small" placeholder="Name des Betriebs" defaultValue={org.name} />
            </Fieldset>

            <Fieldset legend="Adresse" variation="outlined" size="small" className="!mt-4">
              <Flex direction="row">
                <Input type="text" size="small" placeholder="Straße" />
                <Input type="text" size="small" placeholder="Hausnummer" maxLength={6} width="fit-content" />
              </Flex>
              <Flex direction="row">
                <Input type="text" size="small" placeholder="PLZ" maxLength={8} width="fit-content" />
                <Input type="text" size="small" placeholder="Ort" />
              </Flex>
              <Input type="text" size="small" placeholder="Land" />
            </Fieldset>

            {/** @todo: Add section for drivers */}

            <Fieldset legend="Einstellungen" variation="outlined" size="small" className="!mt-4">
              <RadioGroupField size="small" isRequired={true} legend="Gewichtseinheiten" name="units">
                <Radio value="kg">metrisch / kg</Radio>
                <Radio value="lb">angloamerikanisch / lb</Radio>
              </RadioGroupField>

              <SelectField label="Währungszeichen" size="small">
                <option value="EUR">EUR - € Euro</option>
                {/* <option value="CHF">CHF - Schweizer Franken</option>
                <option value="USD">USD - $ Dollar</option>
                <option value="GBP">GBP - £ Pound</option>
                <option value="CNY">CNY - ¥ Yuan Renminbi</option>
                <option value="JPY">JPY - ¥ Yen</option>
                <option value="INR">INR - ₹ Rupee</option> */}
              </SelectField>
            </Fieldset>

            <ButtonGroup className="buttons !mt-4" size="small">
              <Button size="small" variation="primary" type="submit" isDisabled={true}>
                Speichern
              </Button>
              <Link to="..">
                <Button size="small">Abbrechen</Button>
              </Link>
            </ButtonGroup>
          </form>
        </div>
      </div>
    </div>

    {/* <small>* Die Icons "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
