import { get, put } from 'aws-amplify/api'
import { Flex } from '@aws-amplify/ui-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { toLocalStringDe } from 'strtmn'

import { currentSession } from '../../Utils.AmplifyAuth';
import { isDev } from '../../config'
import { ResponseMessage, VehicleCanTracesResponse } from '../../../../../@types/sds/'

// import './VehicleDebug.css'
import VehiclePageHeader from '../VehiclePageHeader'

/** Current can-trace status */
interface CanTraceStatus {
  isCanTraceActive: boolean
  canTraceId?: string
  error?: string
}

interface CanTraceButtonsProps {
  canTraceStatus?: CanTraceStatus
  requestStartCanTrace: () => void
  requestStopCanTrace: () => void
  requestCancelCanTrace: () => void
}
export function CanTraceButtons({ canTraceStatus, requestStartCanTrace, requestStopCanTrace, requestCancelCanTrace }: CanTraceButtonsProps) {
  console.log("> CanTraceButtons")
  console.log("canTraceStatus", canTraceStatus)
  return <Flex direction='column'>
    {canTraceStatus?.error
      ? <div className='error-message'>
        API-Fehler: {canTraceStatus?.error}
      </div>
      : <></>}
    <div style={{
      border: '1px solid #888',
      padding: '4px 0px 4px 6px',
      borderRadius: '4px',
      backgroundColor: 'rgb(241, 241, 241)',
    }}>
      {canTraceStatus?.isCanTraceActive
        ? <div>Aktuell wird der Trace <em>{canTraceStatus.canTraceId}</em> aufgezeichnet.</div>
        : <>Kein CAN-Trace aktiv.</>}
      <Flex>
        {!(canTraceStatus?.isCanTraceActive)
          ? <button id='btn-start-can-trace' onClick={requestStartCanTrace}>CAN-Trace starten</button>
          : <>
            <button id='btn-stop-can-trace' onClick={requestStopCanTrace}>CAN-Trace beenden</button>
            <button id='btn-cancel-can-trace' onClick={requestCancelCanTrace}>CAN-Trace abbrechen</button>
          </>}
      </Flex>
    </div>
  </Flex>
}

export default function VehicleCanTraces() {
  let params = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [maybeData, setData] = useState<VehicleCanTracesResponse | undefined>(undefined)
  const [vin, setVin] = useState<string | undefined>(undefined)
  const [canTraceStatus, setCanTraceStatus] = useState<CanTraceStatus | undefined>(undefined)

  const sendControlCanTraceRequest = async (command: 'start' | 'stop' | 'cancel'): Promise<ResponseMessage> => {
    console.log(`> sendControlCanTraceRequest for ${vin}: ${command}`)
    const tokens = await currentSession()
    const apiName = 'frontendApi'
    const path = `/vehicle/${vin}/can-traces/${command}`
    const options = {
      headers: {
        Authorization: `Bearer ${tokens?.idToken}`
      }
    }
    console.info(`Sending API call to ${path}`)
    const restOperation = put({ apiName, path, options })
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    return apiResponse
  }

  const handleApiError = (err: any) => {
    console.error("err:", JSON.stringify(err.response, null, 2))
    setCanTraceStatus(prev => {
      // console.error("setting can-trace-status w/ error...")
      return {
        isCanTraceActive: prev?.isCanTraceActive ?? false,
        canTraceId: prev?.canTraceId ?? undefined,
        error: err.response.data.res.message
      }
    })
    // console.error("set can-trace-status w/ error")
  }

  const requestStartCanTrace = async () => {
    console.log(`> requestStartCanTrace for ${vin}`)

    console.log("Sending API request")
    sendControlCanTraceRequest('start')
      .then(data => {
        console.log("start.then")
        console.log("data:", data)
        setCanTraceStatus({
          isCanTraceActive: true,
          canTraceId: data.res.traceId
        })
        console.log("canTraceId:", data.res.traceId)
        console.log("canTraceStatus:", JSON.stringify(canTraceStatus, null, 2))
      })
      .catch(err => handleApiError(err))
  }

  const requestStopCanTrace = async () => {
    console.log(`> requestStopCanTrace for ${vin} and ${canTraceStatus?.canTraceId}`)

    console.log("Sending API request")
    sendControlCanTraceRequest('stop')
      .then(data => {
        console.log("stop.then")
        console.log("data:", data)
        setCanTraceStatus({
          isCanTraceActive: false
        })
        console.log("canTraceStatus:", JSON.stringify(canTraceStatus, null, 2))
      })
      .catch(err => handleApiError(err))
  }

  const requestCancelCanTrace = async () => {
    console.log(`> requestCancelCanTrace for ${vin} and ${canTraceStatus?.canTraceId}`)

    console.log("Sending API request")
    sendControlCanTraceRequest('cancel')
      .then(data => {
        console.log("cancel.then")
        console.log("data:", data)
        setCanTraceStatus({
          isCanTraceActive: false
        })
      })
      .catch(err => handleApiError(err))
  }

  useEffect(() => {
    setVin(params.vin)
  }, [params])

  useEffect(() => {
    const getData = async () => {
      if (!vin) {
        console.debug("vin state is empty")
        return
      }
      const tokens = await currentSession()
      const apiName = 'frontendApi'
      const path = `/vehicle/${vin}/can-traces`
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${tokens?.idToken}`
          }
        }
        console.debug(`Sending API call`)
        const restOperation = get({ apiName, path, options })
        const response = await (await restOperation.response).body.json() as unknown as VehicleCanTracesResponse
        const myData = response
        setData(myData)
        /** @todo Handling of can-trace status */
        if (!myData.CanTraceStatus) {
          console.log("no can-trace-status in response")
          setCanTraceStatus(undefined)
        } else {
          console.log("can-trace-status in response:", myData.CanTraceStatus)
          setCanTraceStatus({
            isCanTraceActive: !!myData.CanTraceStatus.traceId,
            canTraceId: myData.CanTraceStatus.traceId
          })
        }
        setError(undefined)
      }
      catch (err) {
        console.error('Error fetching data from api')
        if (isDev) {
          console.error(`Tried to fetch VehicleCanTraces data from ${apiName} :: ${path}`)
        }
        let errMsg: string
        /** @ts-ignore */
        errMsg = err!.response!.data!.message!
        if (!errMsg) {
          errMsg = (err instanceof Error)
            ? err.message
            : String(err)
        }
        setData(undefined)
        setError(errMsg)
      }
      finally {
        setLoading(false)
      }
    }
    getData()
  }, [vin])

  if (loading) {
    return <>
      <VehiclePageHeader vin={vin!} />
      <div>Lade Daten...</div>
    </>
  }

  let errorComponent = null
  if (error) {
    errorComponent = 'development' === process.env.NODE_ENV
      ? <div>Fehler beim Laden der Daten: {error}</div>
      : <div>Fehler beim Laden der Daten.</div>
  }
  if (!maybeData) {
    errorComponent = <div>Fehler beim Laden der Daten: Keine Daten gefunden.</div>
  }
  else if (maybeData.CanTraces.length === 0) {
    errorComponent = <div>Keine CAN-Traces gefunden.</div>
  }

  if (errorComponent) {
    return <>
      <VehiclePageHeader vin={vin!} />
      <CanTraceButtons canTraceStatus={canTraceStatus} requestStartCanTrace={requestStartCanTrace} requestStopCanTrace={requestStopCanTrace} requestCancelCanTrace={requestCancelCanTrace} />

      <h2>Die letzten CAN-Traces</h2>
      {errorComponent}
    </>
  }
  const data = maybeData!
  console.debug(data)

  const firstTrace = data.CanTraces[0]
  const idxOfVin = firstTrace.Key.indexOf(vin!)
  const prefixLength = idxOfVin + vin!.length + 1
  const dateLength = 24 // length of a datetime in iso format (with Z time zone)

  return <>
    <VehiclePageHeader vin={vin!} />
    <CanTraceButtons canTraceStatus={canTraceStatus} requestStartCanTrace={requestStartCanTrace} requestStopCanTrace={requestStopCanTrace} requestCancelCanTrace={requestCancelCanTrace} />

    <h2>Die letzten CAN-Traces</h2>
    <div>{data.CanTraces.length} CAN Traces geladen:</div>
    <table cellSpacing={2} cellPadding={2} border={1} style={{ borderCollapse: 'separate', border: '1px solid black' }}>
      <tbody>
        {data.CanTraces.reverse().map(elem => {
          const fileName = elem.Key.substring(prefixLength)
          let dateTimeLocal = fileName
          const matches = fileName.match(/^dtc_.*_(\d{8})_(\d{6})_.*\.trc/)
          if (matches) {
            // First Florian-Format:
            // dtc_sds-FCNLA_20240430_085351_7252226_0.trc
            // dtc_<THING_NAME>_<DATE>_<TIME>_<RND?>_<FRAGMENT_ID?>.trc
            // <TIME> is local time on the modem
            const date = matches[1]
            const time = matches[2]
            // `manualIso` deliberately has no timezone info
            const manualIso = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}T${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`
            // Date() assumes local (i.e., Browser(!)) timezone
            const manualDateTime = new Date(manualIso)
            const dateTimeStr = manualDateTime.toISOString()
            dateTimeLocal = toLocalStringDe(dateTimeStr)
          } else {
            const dateTimeStr = fileName.substring(0, dateLength)
            dateTimeLocal = toLocalStringDe(dateTimeStr)
          }
          return <tr key={elem.Key}>
            <td>{dateTimeLocal}</td>
            <td>{(elem.Size / 1024).toFixed(2)} KB</td>
            <td><a href={elem.Url}>Download</a></td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}
