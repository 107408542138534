import { Link } from 'react-router-dom'

import { VehicleStatusResponse } from '../../../../../@types/sds/'
import { toHex, toLocalStringDe } from "strtmn"

import { getLinkToTimeseries } from '../../LinkUtils'

import './StatusDetails.css'

interface StatusDetailsProps {
  maschine: VehicleStatusResponse
  [moarProps: string]: any
}

export default function StatusDetails({ maschine, ...props }: StatusDetailsProps) {
  const telemetryData = maschine.Telemetry
  if (!telemetryData) {
    return (
      <div>
        <h2>Nachrichten und Signale</h2>
        <div>Für dieses Fahrzeug können keine Nachrichten und Signale angezeigt werden.</div>
      </div>
    )
  }

  const now = new Date()
  const maxAge = 30 * 24 * 3600 * 1000

  const telemetryList = Object.keys(telemetryData).flatMap(k => {
    // Strip 'DTCs' key
    if ('DTCs' === k
      || !telemetryData.hasOwnProperty(k)) {
      return []
    }
    const item = telemetryData[k]

    let dateStr: string | null = null
    if (item.dt) {
      const dt = new Date(item.dt)
      if (now.getTime() - dt.getTime() > maxAge) {
        return []
      }
      dateStr = toLocalStringDe(item.dt)
    }
    // fallbacks
    if (!dateStr || dateStr.toLocaleLowerCase().includes('invalid')) {
      if (item.dt) {
        dateStr = item.dt
      } else if (item.Timestamp) {
        const dt = new Date(item.Timestamp * 1000)
        if (now.getTime() - dt.getTime() > maxAge) {
          return []
        }
        dateStr = toLocalStringDe(dt.toISOString())
        // isOld denotes entries in an old, deprecated format.
        // It doesn't say a thing about the age of the data.
        item.isOld = true
      } else {
        dateStr = 'invld' // JSON.stringify(item)
      }
    }
    item.DateStr = dateStr
    if (typeof item.Value === "string") {
      const valFloat = parseFloat(item.Value)
      if (!isNaN(valFloat)) {
        item.Value = valFloat
      }
    }
    if (typeof item.Value === "number") {
      // round to max. 2 decimal places
      item.Value = +(Math.round(+(item.Value + "e+2")) + "e-2")
    }
    if ('Location' === k) {
      item.PGN = k
      item.SPN = k
      item.Value = `${item.Latitude} , ${item.Longitude}`
    }
    if (k.startsWith('Presence')) {
      item.PGN = 'Presence'
      item.SPN = k
      item.name = k.endsWith('-aja')
        ? 'Fernwartung'
        : 'Data'
      item.Value = item.eventType
    }

    return item
  })

  const a_before_b = -1
  const b_before_a = 1
  telemetryList.sort((a, b) => {
    // Sort Presence to begin of list
    if ('Presence' === a.PGN) {
      return a_before_b
    }
    if ('Presence' === b.PGN) {
      return b_before_a
    }
    // Sort Location to end of list
    if ('Location' === a.PGN) {
      return b_before_a
    }
    if ('Location' === b.PGN) {
      return a_before_b
    }

    // Then sort by PGN
    if (a.PGN < b.PGN) {
      return a_before_b
    }
    if (b.PGN < a.PGN) {
      return b_before_a
    }

    // Then sort by SourceAddress
    const aSrc = a.SourceAddress ?? 0
    const bSrc = b.SourceAddress ?? 0
    if (aSrc < bSrc) {
      return a_before_b
    }
    if (bSrc < aSrc) {
      return b_before_a
    }

    // Then sort by having a value
    const a_has_value = a.Value !== undefined && a.Value !== null
    const b_has_value = b.Value !== undefined && b.Value !== null
    if (a_has_value && !b_has_value) {
      return a_before_b
    }
    if (b_has_value && !a_has_value) {
      return b_before_a
    }

    // Then sort by numeric value
    if (Number(a.SPN) < Number(b.SPN)) {
      return a_before_b
    }
    if (Number(b.SPN) < Number(a.SPN)) {
      return b_before_a
    }

    return 0
  })

  let currentPgnSrc: string
  return (
    <div>
      <h2>Nachrichten und Signale</h2>
      Alte Einträge sind ausgegraut. Einträge, die zuletzt vor mehr als 30 Tagen übertragen wurden, sind ausgeblendet.
      <table className='telemetry'>
        <thead>
          <tr key='th-row'>
            <th>SPN</th>
            <th>SPN Name</th>
            <th>Value</th>
            <th>Updated at</th>
          </tr>
        </thead>
        <tbody>
          {telemetryList.flatMap(item => {
            const pgnHex = 'Location' === item.PGN || 'Presence' === item.PGN
              ? item.PGN // Location and Presence have non-numeric PGNs
              : toHex(item.PGN, 4)
            const pgnSrc = undefined !== item.SourceAddress
              ? `${pgnHex} from ${toHex(item.SourceAddress, 4)}`
              : pgnHex // fall-back for entries w/o SourceAddress
            let printPgnHeaderRow = (pgnSrc !== currentPgnSrc)
            if (printPgnHeaderRow) {
              currentPgnSrc = pgnSrc
            }
            const cssClasses = item.isOld ? 'isOld' : ''
            let unit = ''
            if (item.unit) {
              unit = `[${item.unit}]`
            }

            const rowKey = 'Location' === item.PGN || 'Presence' === item.PGN
              ? `${item.PGN}-${item.name}`
              : `${pgnSrc}-${item.SPN}`

            const rv = []
            if (printPgnHeaderRow) {
              rv.push(
                <tr key={`hdr-${pgnSrc}`}>
                  <th colSpan={4}>PGN {pgnSrc} {item.pgnName}</th>
                </tr>
              )
            }
            /** @todo Find normalized values and use them */
            // const pgn = item.PGN
            // const spn = item.SPN
            // const sourceAddress = item.SourceAddress
            rv.push(
              <tr className={cssClasses} key={rowKey}>
                <td className='spn'>{item.SPN ?? '---'}</td>
                <td className='spn-name'>
                  <Link to={ getLinkToTimeseries(maschine.vin!, item.PGN, item.SourceAddress, item.SPN) }>
                    {item.spnName ?? item.name}
                  </Link>
                </td>
                <td className='value'>{item.Value} {unit}</td>
                <td className='updated-at'>{item.DateStr}</td>
              </tr>
            )
            return rv
          })}
        </tbody>
      </table>
    </div>
  )
}
