import { createContext, PropsWithChildren, useEffect, useState } from "react"

interface IUserPreferences {
  [moar: string]: string
}
type ISetUserPreferenceFunc = (key: string, value: string) => void

interface IUserPreferencesContext {
  userPreferences?: IUserPreferences
  setUserPreferences?: React.Dispatch<React.SetStateAction<IUserPreferences | undefined>>
  setUserPreference?: ISetUserPreferenceFunc
}
export const UserPreferencesContext = createContext<IUserPreferencesContext>({
  userPreferences: undefined,
  setUserPreferences: undefined,
  setUserPreference: undefined
})

export default function UserPreferencesContextProvider({ children }: PropsWithChildren) {
  const [userPreferences, setUserPreferences] = useState<IUserPreferences | undefined>(undefined)

  useEffect(() => {
    // console.log("App.useEffect (getInitialUserPreferences)")
    const getInitialUserPreferences = () => {
      console.log("> getInitialUserPreferences")
      if (userPreferences) {
        console.log("  getInitialUserPreferences: has userPref", userPreferences)
        return
      }
      const lsValue = localStorage.getItem("sds:userPreferences")
      if (!lsValue) {
        console.log("  getInitialUserPreferences: no userPref in LS -> setting empty")
        setUserPreferences({})
        return
      }
      const fromLS = JSON.parse(lsValue)
      console.log("  ✅ getInitialUserPreferences: found userPref in LS", fromLS)
      setUserPreferences(fromLS)
      console.log("  ✅ getInitialUserPreferences: set state to userPref from LS")
    }

    getInitialUserPreferences()
  }, [userPreferences])

  const setUserPreference = (key: string, value: string) => {
    // console.log("> ✅ App.setUserPreference")
    // console.log("  setUserPreference (before)", userPreferences)
    const updated = {
      ...userPreferences,
      [key]: value
    }
    // console.log("  ✅ App.setUserPreference: storing to LS", updated)
    localStorage.setItem("sds:userPreferences", JSON.stringify(updated))
    // console.log("  setUserPreferences (updated)", updated)
    setUserPreferences(updated)
  }

  return (
    <UserPreferencesContext.Provider value={{
      userPreferences,
      setUserPreferences,
      setUserPreference
    }}>
      {children}
    </UserPreferencesContext.Provider>
  )
}