import { useContext } from "react"
import { Button } from '@aws-amplify/ui-react'
import { Cache } from 'aws-amplify/utils'
import { FaArrowRotateRight } from "react-icons/fa6"

import { UserDataContext } from "../contexts/UserDataContextProvider"

export default function RefreshUserDataButton() {
  const { reloadUserDataAsync } = useContext(UserDataContext)

  const refreshUserData = async (): Promise<boolean> => {
    console.log("Invalidating Cache")
    const cacheKey = "frontendApi#/users/current"
    await Cache.removeItem(cacheKey)

    if (reloadUserDataAsync) {
      console.log("  Reloading user data")
      await reloadUserDataAsync()
      return true
    } else {
      console.warn("  Not reloading user data; callback not set")
      return false
    }
  }

  const refreshVehiclesList = async (): Promise<boolean> => {
    console.log("Invalidating Cache")
    const cacheKey = "vehiclesApi#/vehicles"
    await Cache.removeItem(cacheKey)

    if (false) {
      console.log("  Reloading vehicles list")
      // await appCtx.reloadUserData()
      return true
    } else {
      console.warn("  Not reloading vehicles list; callback not set")
      return false
    }
  }

  const refreshAll = async () => {
    const sU = await refreshUserData()
    const sV = await refreshVehiclesList()
    if (!sU || !sV) {
      window.location.reload()
    }
  }

  return <Button onClick={refreshAll}><FaArrowRotateRight />&nbsp;Aktualisieren</Button>
}
