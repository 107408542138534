/**
 * Properties of a FeedComponent (Komponente / Zutat).
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { boolean, InferType, number, object, string } from "yup"

const FeedComponentIdSchema = object({
  id: string().required()
})

export const FeedComponentSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  group: string().nullable(),
  id: string().required(),
  image: string().nullable(),
  include_in_stats: boolean().required(),
  name: string().required(),
  org_id: string().required(),
  is_dry_mass: boolean().required(),
  price_per_kg: number().nullable().positive(),
  ts_gehalt_percent: number().nullable().positive().min(0).max(100),
  updated_at: string().defined(),
  updated_by: string().defined(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable()
})

/**
 * Create-DTO: All data that's transferred from FE to BE for a create request.
 */
export const FeedComponentCreateSchema = FeedComponentSchema
  .omit(["created_at", "created_by", "updated_at", "updated_by", "deleted_at", "deleted_by", "id"])


/**
 * Update-DTO: All data that's transferred from FE to BE for a update request.
 * 
 * @note Of course, `id` is required for an update. It is taken from the path.
 * @note `id` and `org_id` cannot be changed in the FE, so there's no point including them in the request.
 */
export const FeedComponentUpdateSchema = FeedComponentCreateSchema
  .omit(["org_id"])

/**
 * Delete-DTO: All data that's transferred from FE to BE for a delete request.
 */
export const FeedComponentDeleteSchema = FeedComponentIdSchema.clone()

export type FeedComponentDto = InferType<typeof FeedComponentSchema>
export type FeedComponentCreateDto = InferType<typeof FeedComponentCreateSchema>
export type FeedComponentUpdateDto = InferType<typeof FeedComponentUpdateSchema>
export type FeedComponentDeleteDto = InferType<typeof FeedComponentDeleteSchema>

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
type fcDto = Prettify<FeedComponentDto>
type fcCDto = Prettify<FeedComponentCreateDto>
type fcUDto = Prettify<FeedComponentUpdateDto>
type fcDDto = Prettify<FeedComponentDeleteDto>
