import { get } from 'aws-amplify/api'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { LocationDatapoint } from 'j1939'
import { HasPkSk, TelemetryData, VehicleStatusResponse } from '../../../../../@types/sds/'

import { currentSession } from '../../Utils.AmplifyAuth';
import { isDev } from '../../config'
import VehiclePageHeader from '../VehiclePageHeader'
import * as W from './Widgets'

import './VehicleOverview.css'

interface TelemetryWidgetProps {
  state: HasPkSk
}
function ChargerStatus({ state }: TelemetryWidgetProps) {
  /** @todo use boolean icon widget */
  const isConnected = 'charger_connected' === (state.Value as string).toLowerCase().replace(' ', '_')
  return <W.BooleanTextWidget title='Ladestecker' value={isConnected} trueText='verbunden' falseText='getrennt' />
}

function BatteryStateOfCharge({ state }: TelemetryWidgetProps) {
  return <W.NumberWidget title='Ladezustand' value={state.Value} unit={state.unit} />
}

function BatteryHealthState({ state }: TelemetryWidgetProps) {
  /** @todo color-code state of health */
  return <W.NumberWidget title='Battery Health' value={state.Value} unit={state.unit} />
}

function BatteryMaxCellTemperature({ state }: TelemetryWidgetProps) {
  /** @todo color-code temperature */
  let unit = ''
  if (state.unit) {
    unit = state.unit
    if (unit.toLowerCase().includes('celsius')) {
      unit = '°C'
    }
  }
  return <W.NumberWidget title='Max. Cell Temp.' value={state.Value} unit={unit} />
}

function DieselDrehzahl({ state }: TelemetryWidgetProps) {
  /** @todo color-code state of health */
  return <W.NumberWidget title='Akt. Drehzahl' value={state.Value} unit={state.unit} />
}
function DieselDrehmoment({ state }: TelemetryWidgetProps) {
  /** @todo color-code state of health */
  return <W.NumberWidget title='Akt. Drehmoment' value={state.Value} unit={state.unit} />
}


interface VehicleDashboardProps {
  telemetry: TelemetryData
}
function KahuVehicleDashboard({ telemetry }: VehicleDashboardProps) {
  return <>
    <ChargerStatus state={telemetry["127401_516714"]} />
    <br />
    <BatteryStateOfCharge state={telemetry["127533_5921"]} />
    <br />
    <BatteryHealthState state={telemetry["127533_8121"]} />
    <br />
    <BatteryMaxCellTemperature state={telemetry["127549_8075"]} />
    <br />
  </>
}

function DieselPrimusDashboard({ telemetry }: VehicleDashboardProps) {
  return <>
    <DieselDrehzahl state={telemetry["161794_DiesAktDrehz"]} />
    <br />
    <DieselDrehmoment state={telemetry["161794_DiesAktDrehm"]} />
    <br />
  </>
}

export default function VehicleOverview() {
  let params = useParams()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [maybeData, setData] = React.useState<VehicleStatusResponse | undefined>(undefined)

  React.useEffect(() => {
    const getData = async (vin: string) => {
      const tokens = await currentSession()
      const apiName = 'vehiclesApi'
      const path = `/vehicle/${vin}/status`
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${tokens?.idToken}`
          }
        }
        const restOperation = get({ apiName, path, options })
        const response = await (await restOperation.response).body.json() as unknown as VehicleStatusResponse
        const myData = {
          vin: vin,
          ...response
        }
        // .Items!.map(elem => {
        //   elem.vin = elem.ext_sn
        //   elem.modem_sn = elem.seriennummer
        //   return elem
        // })[0]
        // console.log(response)

        setData(myData)
        setError(undefined)
      }
      catch (err) {
        console.error('Error fetching data from api')
        if (isDev) {
          console.error(`Tried to fetch VehicleStatus data from ${apiName} :: ${path}`)
        }
        console.log(err)
        setData(undefined)
        setError(String(err) as string)
      }
      finally {
        setLoading(false)
      }
    }
    getData(params.vin!)
  }, [params])

  if (loading) {
    return <>
      <VehiclePageHeader vin={params.vin!} />
      <div>Lade Daten...</div>
    </>
  }

  let errorComponent = null
  if (error) {
    errorComponent = isDev
      ? <div>Fehler beim Laden der Daten: {error}</div>
      : <div>Fehler beim Laden der Daten.</div>
  }
  if (!maybeData || null === maybeData.Telemetry) {
    errorComponent = <div>Fehler beim Laden der Daten: Keine Daten gefunden.</div>
  }

  if (errorComponent) {
    return <>
      <VehiclePageHeader vin={params.vin!} />
      {errorComponent}
    </>
  }

  const data = maybeData!
  const telemetryData = data.Telemetry as TelemetryData
  // console.debug(data)
  // console.debug(data.Telemetry['Location'])
  const dashboard = data.vin?.includes('evnet')
    ? <KahuVehicleDashboard telemetry={telemetryData} />
    : <DieselPrimusDashboard telemetry={telemetryData} />

  return (
    <>
      <VehiclePageHeader vin={data.vin!} />

      <W.MapWidget title='Standort' location={telemetryData['Location'] as unknown as LocationDatapoint} />

      <div style={{ width: "fit-content" }}>
        {dashboard}
      </div>
      <strong>Anmerkungen</strong>
      <div>
        Diese Übersicht basiert auf Daten, die der ePrimus im Moment so überträgt.
        Natürlich kann man Datenpunkte hinzufügen, entfernen oder anders darstellen.
      </div>
      <div>
        Zum Ladestecker: Die SPNs 127401_516714, 127565_4990, 127597_516632 können sich gut widersprechen...
      </div>
      {/* <VerbindungsDetails maschine={data} />
      <br /> */}
    </>
  )
}
