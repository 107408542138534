import { FeedComponentDto } from "sds"

/**
 * Takes a filename, or an ImageId of the form `${Title}#${Filename}`, and returns the path to the image.
 * @param filenameOrImageId 
 */
export const getImagePath = (filenameOrImageId: string) => {
  let imageFilename = filenameOrImageId.includes("#")
    ? filenameOrImageId.split("#")[1]
    : filenameOrImageId
  return `/images/fc/${imageFilename}`
}

/**
 * Always returns the "unknown" placeholder image.
 * @param filenameOrImageId 
 */
const getFallbackImagePath = (id: string) => {
  return getImagePath("unknown.png")
}

export const ensureImagePath = (feedComponent: FeedComponentDto, imageSrc: string, setImageSrc: React.Dispatch<React.SetStateAction<string>>) => {
  if (!imageSrc) {
    if (feedComponent.image) {
      console.debug("Using image from feedcomponent")
      setImageSrc(getImagePath(feedComponent.image))
    } else {
      console.log("❌ Using some form of image fallback")
      setImageSrc(getFallbackImagePath(feedComponent.id))
    }
  }
}