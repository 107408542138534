import { get } from 'aws-amplify/api'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { currentSession } from '../../Utils.AmplifyAuth';
import { isDev } from '../../config'
import Fehlerliste from './Fehlerliste'
import StatusDetails from './StatusDetails'

import { TelemetryData, VehicleStatusResponse } from '../../../../../@types/sds/'

import './VehicleDebug.css'
import VehiclePageHeader from '../VehiclePageHeader'

export default function VehicleDebug() {
  let params = useParams()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [maybeData, setData] = React.useState<VehicleStatusResponse | undefined>(undefined)

  React.useEffect(() => {
    const getData = async (vin: string) => {
      const tokens = await currentSession()
      const apiName = 'vehiclesApi'
      const path = `/vehicle/${vin}/status`
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${tokens?.idToken}`
          }
        }
        const restOperation = get({ apiName, path, options })
        const response = await (await restOperation.response).body.json() as unknown as VehicleStatusResponse
        const myData = {
          vin: vin,
          ...response
        }
        // .Items!.map(elem => {
        //   elem.vin = elem.ext_sn
        //   elem.modem_sn = elem.seriennummer
        //   return elem
        // })[0]
        // console.log(response)

        setData(myData)
        setError(undefined)
      }
      catch (err) {
        console.error('Error fetching data from api')
        if (isDev) {
          console.error(`Tried to fetch VehicleStatus data from ${apiName} :: ${path}`)
        }
        console.log(err)
        setData(undefined)
        setError(String(err) as string)
      }
      finally {
        setLoading(false)
      }
    }
    getData(params.vin!)
  }, [params])

  if (loading) {
    return <>
      <VehiclePageHeader vin={params.vin!} />
      <div>Lade Daten...</div>
    </>
  }

  let errorComponent = null
  if (error) {
    console.error('Fehler in VehicleDebug')
    errorComponent = isDev
      ? <div>Fehler beim Laden der Daten: {error}</div>
      : <div>Fehler beim Laden der Daten.</div>
  }
  if (!maybeData) {
    errorComponent = <div>Fehler beim Laden der Daten: Keine Daten gefunden.</div>
  }

  if (errorComponent) {
    return <>
      <VehiclePageHeader vin={params.vin!} />
      {errorComponent}
    </>
  }

  const data = maybeData!
  console.debug(data)
  const telemetryData = data.Telemetry as TelemetryData
  return (
    <>
      <VehiclePageHeader vin={data.vin!} />
      {/* <StandortDetails maschine={data} />
      <br /> */}
      {/* <VerbindungsDetails maschine={data} />
      <br /> */}
      {/* <div><a href='reports/'><button className='reports-link'>Berichte ansehen</button></a></div>
      <br /> */}
      <Fehlerliste vin={data.vin!} telemetryData={telemetryData} maxDisplayLength={10} />
      <br />
      <StatusDetails maschine={data} />
      <br />
      {/* <RulesDetails maschine={data} />
      <br /> */}
      {/* <SonstigeDetails maschine={data} /> */}
    </>
  )
}
