import { useContext } from "react"
import { Navigate } from "react-router-dom"

import { UserPreferencesContext } from "../contexts/UserPreferencesContextProvider"
import { CacheKeys } from "../CacheKeys"
import { getLinkToOrgById } from "../LinkUtils"

export default function HomepageRedirect() {
  const { userPreferences } = useContext(UserPreferencesContext)
  // console.log("appCtx:", appCtx)
  const activeOrgId = userPreferences?.[CacheKeys.UserPreferences.ActiveOrgId]
  if (!activeOrgId) {
    return <>Hmmm, keine actorgid</>
  }
  return <Navigate to={getLinkToOrgById(activeOrgId, false)} />
}
