import { useContext } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Collection, Flex } from '@aws-amplify/ui-react'
import { FaChartSimple, FaTractor } from 'react-icons/fa6'

import { Device } from '../../../../../@types/sds'

import { getLinkToVehicleDebug } from '../../LinkUtils'
import { CacheKeys } from '../../CacheKeys'
import { fakes } from '../../messe-fakes'
import ConnectivityIndicator from '../../components/ConnectivityIndicator'
import { UserPreferencesContext } from '../../contexts/UserPreferencesContextProvider'
import { FilterAndSort } from '../orgs/-components/FilterAndSort'
import { ListItem, StarStates } from '../orgs/-components/ListItem'

import './VehiclesListPage.css'

function renderConnectivityStatus(item: Device): React.ReactNode {
  let dataDisabled = true
  let dataConnected = false
  const dataPresence = item['presence']['data']['eventType'] || 'unknown'
  if ('unknown' !== dataPresence) {
    dataDisabled = false
    if ('connected' === dataPresence) {
      dataConnected = true
    }
  }

  let ajaDisabled = true
  let ajaConnected = false
  const ajaPresence = item['presence']['aja']['eventType'] || 'unknown'
  if ('unknown' !== ajaPresence) {
    ajaDisabled = false
    if ('connected' === ajaPresence) {
      ajaConnected = true
    }
  }

  return <span style={{
    color: "#242424"
  }}>
    <ConnectivityIndicator name='Daten' isConnected={dataConnected} isDisabled={dataDisabled} />
    <ConnectivityIndicator name='Fernwartung' isConnected={ajaConnected} isDisabled={ajaDisabled} />
  </span>
}

function renderFunc(item: Device, index: number) {
  const machineType: string = item.m_machineType || ""
  const machineTypeLc = machineType.toLowerCase()
  const connectivityStatus = renderConnectivityStatus(item)
  const titleNode: React.ReactNode = <>{connectivityStatus} {item.vin!}</>
  const details = <>
    {machineType || "Experiment"}<br />
    {item.adresse
      ? item.adresse.ort
      : "Ort: nicht angegeben"}<br />
    Modem S/N: {item.modem_sn}<br />
  </>
  let imageName: string = "unknown-machine-type.png"
  if (machineTypeLc.startsWith("p600") || machineTypeLc.startsWith("primus600")) {
    imageName = "primus-600.jpg"
  }
  if (machineTypeLc.startsWith("p900") || machineTypeLc.startsWith("primus900")) {
    imageName = "primus-900.jpg"
  }
  if (machineTypeLc.startsWith("everti") || machineTypeLc.startsWith("e-verti")) {
    imageName = "e-vertifeed.jpg"
  }
  if (machineTypeLc.startsWith("eprimus") || machineTypeLc.startsWith("e-primus")) {
    imageName = "e-primus.jpg"
  }
  const imageSrc = imageName && `/images/${imageName}`
  return <ListItem
    key={item.vin!}
    className="vehicle"
    title={titleNode}
    // href={getLinkToVehicleDebug(item)}
    imageSrc={imageSrc}
    details={details}
    starState={StarStates.hidden}
    viewButtonIcon={<FaChartSimple />} />
}

function VehiclesListPage() {
  const { userPreferences } = useContext(UserPreferencesContext)
  const activeOrgId = userPreferences?.[CacheKeys.UserPreferences.ActiveOrgId]

  /** @todo Filtering is a Messe fake */
  let validModems: string[] = []
  switch (activeOrgId) {

    case fakes.orgIds.hag:
      validModems = [...fakes.modemIds.allOrgs, ...fakes.modemIds.hag]
      break
    case fakes.orgIds.gb:
      validModems = [...fakes.modemIds.allOrgs, ...fakes.modemIds.gb]
      break
  }

  const title = "Meine Fahrzeuge"
  const icon = <FaTractor className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const items = useLoaderData() as Device[]
  const _className = "vehicles"

  /** @todo Filtering is a Messe fake */
  let filteredItems
  if (!validModems || 0 === validModems.length) {
    filteredItems = items
  } else {
    // Use PK, since seriennummer is not unique
    filteredItems = items.filter(item => validModems.includes(item.PK))
  }
  filteredItems?.forEach(item => {
    console.log(item)
    if (item.ext_sn?.indexOf("GPIO") > -1
      || item?.ext_sn === "W09653110P0S31301"
      || item?.ext_sn === "W09653110P0S31302") {
      item.m_machineType = "P600"
    }
    if (item.m_machineType?.indexOf("eVertiFeed") > -1) {
      item.m_machineType = "eVertiFeed"
    }
  })

  // derived from ListPage
  return <div className="rm-list-page">
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>

      {/* <Flex className="!justify-center !items-center gap-2.5">
          <Link to='new'>
            <Button className="button-add has-icon"><span role="img" aria-label="add"><FaPlus /></span></Button>
          </Link>
        </Flex> */}
    </Flex>

    <div className="rm-list-container">
      <FilterAndSort />

      <Collection
        type="list"
        items={filteredItems}
        className={`rm-list ${_className}`}>
        {(item, index) => renderFunc(item, index)}
      </Collection>
    </div>
  </div>
}

export { loader } from '../../components/maschinen-liste/VehiclesList'
export const Component = VehiclesListPage
