import * as React from 'react'
import { useNavigate } from 'react-router-dom'

// import ConnectivityIndicator from './ConnectivityIndicator'
import QueryNavLink from './maschinen-liste/QueryNavLink'
import ConnectivityIndicator from './ConnectivityIndicator'
import { getLinkToModem, getLinkToVehicleDebug } from '../LinkUtils'
import { Device } from '../../../../@types/sds/'

import './DevicesTable.css'

interface DevicesTableRowProps {
  className?: string
  device: Device
  fields: string[]
  singleRow: boolean
}
export const DevicesTableRow = ({ className, fields, device, singleRow }: DevicesTableRowProps) => {
  const navigate = useNavigate()
  return <tr className={className} key={`${device.vin}-${device.modem_sn}`} onClick={() => { navigate(getLinkToVehicleDebug(device)) }}>
    {fields.flatMap(f => {
      let mapped: React.ReactNode = device[f] ?? 'n/a'
      if ('vin' === f && device[f]) {
        if (!singleRow) {
          return []
        }
        mapped = <QueryNavLink
          className='vehicles-link'
          to={getLinkToVehicleDebug(device)}>
          {device.vin}
        </QueryNavLink>
      }
      if ('modem_sn' === f && device[f]) {
        mapped = <QueryNavLink
          className='modem-link'
          to={getLinkToModem(device)}>
          {device[f]}
        </QueryNavLink>
      }
      if ('connection_status' === f) {
        let dataDisabled = true
        let dataConnected = false
        const dataPresence = device['presence']['data']['eventType']
        if ('unknown' !== dataPresence) {
          dataDisabled = false
          if ('connected' === dataPresence) {
            dataConnected = true
          }
        }

        let ajaDisabled = true
        let ajaConnected = false
        const ajaPresence = device['presence']['aja']['eventType']
        if ('unknown' !== ajaPresence) {
          ajaDisabled = false
          if ('connected' === ajaPresence) {
            ajaConnected = true
          }
        }

        mapped = <>
          <ConnectivityIndicator name='Daten' isConnected={dataConnected} isDisabled={dataDisabled} />
          <ConnectivityIndicator name='Fernwartung' isConnected={ajaConnected} isDisabled={ajaDisabled} />
        </>
      }
      return <td key={`${device.vin}-${device.modem_sn}-${f}`}>{mapped}</td>
    })}
  </tr>
}

interface DevicesTableProps {
  headings: string[]
  fields: string[]
  devices: Device[]
  singleRow: boolean
}
export const DevicesTable = ({ headings, fields, devices, singleRow }: DevicesTableProps) => {
  const navigate = useNavigate()
  const numCols = fields.length - 1
  const thead = singleRow
    ? <thead>
      <tr>
        {headings.flatMap(h => <th key={h}>{h}</th>)}
      </tr>
    </thead>
    : <thead>
      <tr>
        <th>VIN</th>
        <th colSpan={numCols - 1}></th>
      </tr>
      <tr>
        {headings.flatMap(h => {
          if ('vin' === h.toLowerCase()) {
            return []
          }
          return <th key={h}>{h}</th>
        })}
      </tr>
    </thead>

  return <table className='devices-table'>
    {thead}
    <tbody>
      {devices.map(d => singleRow
        ? <DevicesTableRow key={`${d.vin}-${d.modem_sn}`} device={d} fields={fields} singleRow={singleRow} />
        : <React.Fragment key={`${d.vin}-${d.modem_sn}`}>
          <tr className='device-id' onClick={() => { navigate(getLinkToVehicleDebug(d)) }}>
            <td colSpan={numCols}>
              <QueryNavLink
                className='vehicles-link'
                to={getLinkToVehicleDebug(d)}>
                {d.vin}
              </QueryNavLink>
            </td>
          </tr>
          <DevicesTableRow className='device-details' device={d} fields={fields} singleRow={singleRow} />
        </React.Fragment>)}
    </tbody>
  </table>
}
