/**
 * Mapping between a Recipe and its FeedComponents.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { InferType, number, object, string } from "yup"

export const RecipeComponentSchema = object({
  feed_component_id: string().required(),
  mass_kg: number().required().min(0),
  mixing_time_s: number().min(0),
  order: number().positive(),
  recipe_id: string().required(),
})

export type RecipeComponent = InferType<typeof RecipeComponentSchema>
