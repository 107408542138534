import { Badge, Flex } from "@aws-amplify/ui-react"

import { Organization, RecipeDto } from "sds"

import config from "../../../config"
import { UNIT_OF_MASS } from "../../../org-settings"
import { UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { getLinkToOrgRecipe } from "../../../LinkUtils"
import { getTotalFreshMass, getTotalNumberOfAnimals } from "../recipes/Recipes"

import { imageHash, ListItem, StarStates } from "./ListItem"

const NUM_RC_IMAGES = config.images.rc.numImages
const IMAGE_HASH_SEED = config.images.rc.imageHashSeed

interface RecipeItemProps {
  className?: string
  org: Organization
  item: RecipeDto
  hasViewButton?: boolean
  hideFavStar?: boolean
}

/**
 * A ListItem representing a Recipe.
 * Used in
 *   - /org/:orgId
 *     - fav-star
 *     - has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /animal-groups/:animalGroupId
 *     - ! fav-star
 *     - ! has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /feed-components/:feedComponentId
 *     - ! fav-star
 *     - ! has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 *   - /recipes/:recipeId
 *     - fav-star
 *     - has-view-button
 *     - ag-badges
 *     - animal count
 *     - weight per animal
 *     - total weight
 * @returns 
 */
export default function RecipeItem({ className, org, item, hideFavStar, hasViewButton = true }: RecipeItemProps) {
  /** @todo Hide favorites for Eurotier */
  hideFavStar = true
  const recipe = item as RecipeDto
  const imageIdx = imageHash(IMAGE_HASH_SEED, recipe.id, NUM_RC_IMAGES)
  const imageSrc = `/images/recipes/ration-pixabay-${imageIdx}_s.jpg`
  const numAnimals = getTotalNumberOfAnimals(org, recipe)
  const freshMassPerAnimal = getTotalFreshMass(org, recipe)
  const details = <>
    <Flex wrap="wrap" className="!gap-1.5 !gap-y-1">
      {recipe.RecipesAusladegruppen
        .sort((a, b) => {
          if (a.order && !b.order) {
            return -1
          }
          if (!a.order && b.order) {
            return 1
          }
          if (!a.order && !b.order) {
            return 0
          }
          return (a.order! < b.order! ? -1 : 1)
        })
        .map(ra => {
          const ag = UserDataContextHelper
            .findAnimalGroup(org, ra.ausladegruppe_id)
          return <Badge size="small" className="!text-nowrap" fontWeight="normal" key={ag.name}>{ag.name}</Badge>
        })}
    </Flex>
    {`${numAnimals} Tiere`} <br />
    {`Gewicht: ${(freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 1 })} ${UNIT_OF_MASS} pro Tier`}<br />
    {`Gesamtgewicht: ${(numAnimals * freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${UNIT_OF_MASS}`}<br />
  </>

  return <ListItem
    key={recipe.id}
    className={`recipe ${className}`}
    title={recipe.name}
    href={getLinkToOrgRecipe(org, recipe.id, false)}
    imageSrc={imageSrc}
    details={details}
    hasViewButton={hasViewButton}
    starState={hideFavStar
      ? StarStates.hidden
      : StarStates.filled} />
}