/** A type with a `message` property. */
type ErrorWithMessage = {
  message: string
}

/** Checks if an object has a `message` property of type `string`. */
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  )
}

/** Ensures that an object has a `message` property of type `string`.
 * @param maybeError 
 * @returns If {@linkcode maybeError} has such a property it is returned unchanged. Otherwise a new `Error` object is generated with the serialized {@linkcode maybeError} as its `message`
 */
function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

/** Tries some strategies to extract the message from {@linkcode error} which may be an `Error` object or a `string`.
 * @param error 
 * @returns 
 */
export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}
