import { Card } from "@aws-amplify/ui-react"
import { FaRegStar, FaStar } from "react-icons/fa6"
import { ChartData, Chart as ChartJS } from "chart.js"
import 'chartjs-adapter-luxon'
import { Line } from "react-chartjs-2"
import annotationPlugin from 'chartjs-plugin-annotation';

import { PointInTime } from "./ChartUtils"
import { StarStates } from "./ListItem"

ChartJS.register(
  annotationPlugin
)

type GenauigkeitSingleChartProps = {
  _className?: string
  title?: string
  series: ChartData<'line', PointInTime[], unknown>
  chartOptions: any
  starState?: StarStates
}
export default function GenauigkeitSingleChart({ _className, title, series, chartOptions, starState = StarStates.hidden }: GenauigkeitSingleChartProps) {
  return <Card className={`rm-list-item ${_className}`}>
    <div className="info w-full">
      {title && <div className="title">{title} {
        (starState === StarStates.hidden)
          ? '' :
          (starState === StarStates.empty)
            ? <FaRegStar className="fav" />
            : <FaStar className="fav is-fav" />
      }</div>}
      <div className="details w-full !mt-0">
        <Line data={series} options={chartOptions} />
      </div>
    </div>
  </Card>
}
