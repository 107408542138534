/**
 * Properties of an Ausladegruppe.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { InferType, number, object, string } from "yup"

const AnimalGroupIdSchema = object({
  id: string().required()
})

export const AnimalGroupSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  id: string().required(),
  name: string().required(),
  number_of_animals: number().required().positive().integer(),
  org_id: string().required(),
  updated_at: string().defined(),
  updated_by: string().defined(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable()
})

/** @deprecated Use `AnimalGroupSchema` instead. */
export const AusladegruppeSchema = AnimalGroupSchema
/** @deprecated Use `AnimalGroupDto` instead. */
export type Ausladegruppe = InferType<typeof AusladegruppeSchema>


/**
 * Create-DTO: All data that's transferred from FE to BE for a create request.
 */
export const AnimalGroupCreateSchema = AnimalGroupSchema
  .omit(["created_at", "created_by", "updated_at", "updated_by", "deleted_at", "deleted_by", "id"])

/**
 * Update-DTO: All data that's transferred from FE to BE for a update request.
 * 
 * @note Of course, `id` is required for an update. It is taken from the path.
 * @note `id` and `org_id` cannot be changed in the FE, so there's no point including them in the request.
 */
export const AnimalGroupUpdateSchema = AnimalGroupCreateSchema
  .omit(["org_id"])

/**
 * Delete-DTO: All data that's transferred from FE to BE for a delete request.
 */
export const AnimalGroupDeleteSchema = AnimalGroupIdSchema.clone()

export type AnimalGroupDto = InferType<typeof AnimalGroupSchema>
export type AnimalGroupCreateDto = InferType<typeof AnimalGroupCreateSchema>
export type AnimalGroupUpdateDto = InferType<typeof AnimalGroupUpdateSchema>
export type AnimalGroupDeleteDto = InferType<typeof AnimalGroupDeleteSchema>

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
type agDto = Prettify<AnimalGroupDto>
type agCDto = Prettify<AnimalGroupCreateDto>
type agUDto = Prettify<AnimalGroupUpdateDto>
type agDDto = Prettify<AnimalGroupDeleteDto>
