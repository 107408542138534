import { Organization } from "sds"
import { Device } from "../../../@types/sds/"

export const getLinkToVehicleOverview = (vehicle: Device) => {
  // This function has maschine by design.
  // This way, we can switch links from internal to external serial numbers in the future.
  return `/vehicles/${vehicle.vin}/`
}
export const getLinkToVehicleCanTraces = (vehicle: Device) => {
  // This function has maschine by design.
  // This way, we can switch links from internal to external serial numbers in the future.
  return `/vehicles/${vehicle.vin}/can-traces/`
}
export const getLinkToVehicleDebug = (vehicle: Device) => {
  // This function has maschine by design.
  // This way, we can switch links from internal to external serial numbers in the future.
  return `/vehicles/${vehicle.vin}/debug/`
}
export const getLinkToVehicleReport = (vehicle: Device) => {
  // This function has maschine by design.
  // This way, we can switch links from internal to external serial numbers in the future.
  return `/vehicles/${vehicle.vin}/report/`
}
export const getLinkToTimeseries = (vin: string, pgn: string, sourceAddress: string, spn: string) => {
  if (!sourceAddress) {
    sourceAddress = '0'
  }
  return `/vehicles/${vin}/history/${encodeURIComponent(pgn)}/${encodeURIComponent(sourceAddress)}/${encodeURIComponent(spn)}/`
}
export const getLinkToDtcTimeseries = (vin: string) => {
  return `/vehicles/${vin}/history/dtcs/`
}
export const getLinkToModem = (vehicle: Device) => {
  // This function has maschine by design.
  // This way, we can switch links from internal to external serial numbers in the future.
  return `/modems/${vehicle.modem_sn}/`
}

export const getLinkToOrg = (org: Organization, editMode: boolean) => getLinkToOrgById(org.id, editMode)
export const getLinkToOrgById = (orgId: string, editMode: boolean) => `/orgs/${orgId}` + (editMode ? '/edit' : '')
export const getLinkToOrgRecipes = (org: Organization) => `/orgs/${org.id}/recipes/`
export const getLinkToOrgRecipe = (org: Organization, recipeId: string, editMode: boolean) => `${getLinkToOrgRecipes(org)}${recipeId}` + (editMode ? '/edit' : '')
export const getLinkToOrgRations = (org: Organization) => `/orgs/${org.id}/rations/`
export const getLinkToOrgFeedComponents = (org: Organization) => `/orgs/${org.id}/feed-components/`
export const getLinkToOrgFeedComponent = (org: Organization, feedComponentId: string, editMode: boolean) => `${getLinkToOrgFeedComponents(org)}${feedComponentId}` + (editMode ? '/edit' : '')
export const getLinkToOrgAnimalGroups = (org: Organization) => `/orgs/${org.id}/animal-groups/`
export const getLinkToOrgAnimalGroup = (org: Organization, animalGroupId: string, editMode: boolean) => `${getLinkToOrgAnimalGroups(org)}${animalGroupId}` + (editMode ? '/edit' : '')
// export const getLinkToOrgRations = (org: Organization) => `/orgs/${org.id}/rations/`
export const getLinkToOrgVehicles = (org: Organization) => `/orgs/${org.id}/vehicles/`
