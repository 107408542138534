import { Link } from 'react-router-dom'
import { DtcEntry, LampStatus } from 'j1939'
import { parseTimestamp, toHex, toLocalStringDe } from "strtmn"
import { TelemetryData } from '../../../../../@types/sds/'
import { getLinkToDtcTimeseries } from '../../LinkUtils'

import './Fehlerliste.css'

interface FehlerlisteProps {
  vin: string
  telemetryData: TelemetryData
  [moarProps: string]: any
}

interface FehlereintragProps {
  fehler: DtcEntry
  [moarProps: string]: any
}

interface LampProps {
  type: string
  status: string
  [moarProps: string]: any
}

interface LampsProps {
  dtcs: DtcEntry[] | null
  [moarProps: string]: any
}

interface TextWithTooltipProps {
  children: any
  tooltip: string
  [moarProps: string]: any
}
function TextWithTooltip({ children, tooltip, ...props }: TextWithTooltipProps) {
  return <span title={tooltip} className='text-with-tooltip'>{children}</span>
}

type LinkToDtcsHistoryProps = {
  vin: string
}
const LinkToDtcsHistory = ({ vin, ...props }: LinkToDtcsHistoryProps) => {
  return <Link to={getLinkToDtcTimeseries(vin)}>DTC History</Link>
}

export type DtcTableProps = {
  dtcsSorted: DtcEntry[]
}
export function DtcTable({ dtcsSorted, ...props }: DtcTableProps) {
  const anyDtcHasTimestamp = dtcsSorted.find(dtc => dtc.Timestamp)
  const anyDtcHasSourceAddress = dtcsSorted.find(dtc => dtc.SourceAddress)
  return <table className="stoerungen">
    <thead>
      <tr>
        {anyDtcHasTimestamp
          ? <>
            <th>Timestamp</th>
            <th></th>
          </>
          : <></>
        }
        {anyDtcHasSourceAddress
          ? <th>Source<br />Address</th>
          : <th>Modul</th>}
        <th></th>
        <th>Störung</th>
        <th></th>
        <th>Failure Mode<br />Identifier</th>
        <th></th>
        <th>Aktiv?</th>
        <th></th>
        <th>Occurence<br />Count</th>
        <th></th>
        <th>SPN</th>
      </tr>
    </thead>
    <tbody>
      {dtcsSorted.map((dtcEntry: DtcEntry) => (<Fehlereintrag key={getDtcEntryKey(dtcEntry)} fehler={dtcEntry} />))}
    </tbody>
  </table>
}

export default function Fehlerliste({ telemetryData, vin, ...props }: FehlerlisteProps) {
  // console.log(telemetryData)
  const dtcBlock = telemetryData.DTCs
  // console.log(dtcBlock)
  const dtcTimestamp: string | undefined = dtcBlock?.dt
  const dtcs: DtcEntry[] | undefined = dtcBlock?.Value
  // console.log(dtcs?.length, "dtcs:", dtcs)

  // case of empty error list
  if (!dtcs || dtcs.length === 0) {
    return (
      <>
        <div>
          <h2>Störungsfrei</h2>
          <div>Für dieses Fahrzeug liegen keine Störungen vor.</div>
          <LinkToDtcsHistory vin={vin} />
        </div>

        <br />
        <Lamps dtcs={null} />
      </>
    )
  }

  const dtcKeys = new Set<string>()
  const uniqueDtcs = dtcs.flatMap(dtcEntry => {
    const key = getDtcEntryKey(dtcEntry)
    if (dtcKeys.has(key)) {
      return []
    }
    dtcKeys.add(key)
    return dtcEntry
  })
  const dtcsSorted = uniqueDtcs
    .sort((e1: DtcEntry, e2: DtcEntry) => {
      // neg. if first is less
      // 0 if equal
      // pos. otherwise
      if (e1.IsActive && !e2.IsActive) {
        return -1
      }
      if (!e1.IsActive && e2.IsActive) {
        return 1
      }
      return (e1.ModuleType ?? 'ModuleType n/a') < (e2.ModuleType ?? 'ModuleType n/a') ? -1 : 1
    })

  return (
    <>
      <div>
        <h2>Störungen</h2>
        {dtcs.length} Einträge | <LinkToDtcsHistory vin={vin} /><br />
        {/* Aktuell haben DTCs keinen Zeitstempel. */}
        Alle diese DTCs wurden am <strong>{toLocalStringDe(dtcTimestamp as string).replace(', ', ' um ')}</strong> übertragen.<br />

        <DtcTable dtcsSorted={dtcsSorted} />
      </div>

      <br />
      <Lamps dtcs={dtcsSorted} />
    </>
  )
}

function Fehlereintrag({ fehler, ...props }: FehlereintragProps) {
  const rowClassName = fehler.IsActive ? 'stoerung-recent' : 'stoerung-old'
  const dtLocal = fehler.Timestamp
    ? toLocalStringDe(parseTimestamp(fehler.Timestamp).toISOString())
    : ''
  return (
    <tr className={rowClassName}>
      {fehler.Timestamp
        ? <>
          <td>{dtLocal}</td>
          <td>&nbsp;</td>
        </>
        : <></>
      }
      <td>
        {fehler.SourceAddress === undefined || fehler.SourceAddress === null
          ? <TextWithTooltip tooltip={fehler.ModuleSoftwareVersion ?? 'ModuleSoftwareVersion n/a'}>
            {fehler.ModuleType ?? 'n/a'}
          </TextWithTooltip>
          : toHex(fehler.SourceAddress, 4)
        }
      </td>
      <td>&nbsp;</td>
      <td>{fehler.SPNName ?? 'SPN:' + toHex(fehler.SPN, 4)}</td>
      <td>&nbsp;</td>
      <td>{fehler.FailureModeName}</td>
      <td>&nbsp;</td>
      <td>{fehler.IsActive ? 'Ja' : 'Nein'}</td>
      <td>&nbsp;</td>
      <td>{fehler.Occurence}</td>
      <td>&nbsp;</td>
      <td>{toHex(fehler.SPN, 4)}</td>
    </tr>
  )
}

function Lamp({ type, status, ...props }: LampProps) {
  const cssClasses = ['lamp', `${type}-lamp`]
  if ('off' !== status.toLowerCase()) {
    cssClasses.push('active')
  }
  return (
    <div className={cssClasses.join(' ')}>{status}</div>
  )
}

function Lamps({ dtcs, ...props }: LampsProps) {
  if (!dtcs || dtcs.length === 0) {
    return (
      <div>
        <strong>Lampen</strong><br />
        Ohne DTCs sind alle Lampen aus.
      </div>
    )
  }

  const lamps = getHighestLampStatus(dtcs)
  return (
    <div>
      <strong>Lampen</strong><br />

      <table className='lamps'>
        <thead>
          <tr key='th-row'>
            <th>Stop</th>
            <th>Protect</th>
            <th>Warning</th>
            <th>Malfunction</th>
          </tr>
        </thead>
        <tbody>
          <tr key='lamps-row'>
            <td><Lamp type='stop' status={lamps.StopLampStatusName} /></td>
            <td><Lamp type='protect' status={lamps.ProtectLampStatusName} /></td>
            <td><Lamp type='warning' status={lamps.WarningLampStatusName} /></td>
            <td><Lamp type='malfunction' status={lamps.MalfunctionLampStatusName} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export interface AllLampsStatus {
  MalfunctionLamp: LampStatus,
  MalfunctionLampStatusName: string,
  ProtectLamp: LampStatus,
  ProtectLampStatusName: string,
  StopLamp: LampStatus,
  StopLampStatusName: string,
  WarningLamp: LampStatus,
  WarningLampStatusName: string,
}

export const getHighestLampStatus = (dtcs: DtcEntry[]): AllLampsStatus => {
  const lamps: AllLampsStatus = {
    MalfunctionLamp: LampStatus.Off,
    MalfunctionLampStatusName: 'Off',
    ProtectLamp: LampStatus.Off,
    ProtectLampStatusName: 'Off',
    StopLamp: LampStatus.Off,
    StopLampStatusName: 'Off',
    WarningLamp: LampStatus.Off,
    WarningLampStatusName: 'Off',
  }
  dtcs.forEach(dtc => {
    if (!dtc.IsActive) {
      return
    }
    if (dtc.MalfunctionLamp > lamps.MalfunctionLamp) {
      lamps.MalfunctionLamp = dtc.MalfunctionLamp
      lamps.MalfunctionLampStatusName = dtc.MalfunctionLampStatusName!
    }
    if (dtc.ProtectLamp > lamps.ProtectLamp) {
      lamps.ProtectLamp = dtc.ProtectLamp
      lamps.ProtectLampStatusName = dtc.ProtectLampStatusName!
    }
    if (dtc.StopLamp > lamps.StopLamp) {
      lamps.StopLamp = dtc.StopLamp
      lamps.StopLampStatusName = dtc.StopLampStatusName!
    }
    if (dtc.WarningLamp > lamps.WarningLamp) {
      lamps.WarningLamp = dtc.WarningLamp
      lamps.WarningLampStatusName = dtc.WarningLampStatusName!
    }
  })
  return lamps
}

const getDtcEntryKey = (dtcEntry: DtcEntry): string => {
  return `${dtcEntry.ModuleType ?? 'src' + dtcEntry.SourceAddress}-${dtcEntry.SPN}-${dtcEntry.Timestamp}`
}