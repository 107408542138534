import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Card, Collection } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaRegStar, FaXmark } from "react-icons/fa6"

import { Organization, RecipeComponent, RecipesAusladegruppen } from "sds"

import config from "../../../config"
import { UNIT_OF_MASS } from "../../../org-settings"
import { getLinkToOrgRecipe } from "../../../LinkUtils"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"

import { imageHash } from "../-components/ListItem"
import AnimalGroupItem from "../-components/AnimalGroupItem"
import FeedComponentItem from "../-components/FeedComponentItem"
import { getTotalFreshMass, getTotalNumberOfAnimals } from "./Recipes"

import '../RecipeManagement.css'

interface RecipeAusladegruppenProps {
  org: Organization
  recipeAusladegruppen: RecipesAusladegruppen[]
  freshMassPerAnimal: number
}
function RecipeAusladegruppen({ org, recipeAusladegruppen, freshMassPerAnimal }: RecipeAusladegruppenProps) {
  return (
    <Collection className="recipe-animal-groups" type="list" direction="column"
      items={recipeAusladegruppen}>
      {(item, index) => {
        const ag = UserDataContextHelper.findAnimalGroup(org, item.ausladegruppe_id)
        const extraDetails = <div>Gesamtgewicht: {(ag.number_of_animals * freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 0 })} {UNIT_OF_MASS}</div>
        return <AnimalGroupItem key={`${item.ausladegruppe_id}-${item.order}`} org={org} item={ag} extraDetails={extraDetails} hasViewButton={false} hideFavStar={true} />
      }}
    </Collection>
  )
}

interface RecipeComponentsListProps {
  org: Organization
  recipeComponents: RecipeComponent[]
}
function RecipeComponentsList({ org, recipeComponents }: RecipeComponentsListProps) {
  return (
    <Collection className="recipe-components" type="list" direction="column"
      items={recipeComponents}>
      {(item, index) => {
        const fc = UserDataContextHelper.findFeedComponent(org, item.feed_component_id)
        let freshMass = item.mass_kg
        if (fc.is_dry_mass) {
          if (fc.ts_gehalt_percent) {
            freshMass = item.mass_kg / fc.ts_gehalt_percent * 100
          }
        }
        return <FeedComponentItem key={`${item.feed_component_id}-${item.order}`} org={org} item={fc} details={`${freshMass} ${UNIT_OF_MASS}`} hasViewButton={false} />
      }}
    </Collection>
  )
}

const NUM_RC_IMAGES = config.images.rc.numImages
const IMAGE_HASH_SEED = config.images.rc.imageHashSeed

export default function Recipe() {
  const params = useParams()

  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const recipe = UserDataContextHelper.findRecipe(org, params.recipeId!)

  recipe.RecipeComponents.sort((a, b) => (a.order || 0) - (b.order || 0))
  recipe.RecipesAusladegruppen.sort((a, b) => (a.order || 0) - (b.order || 0))
  const imageIdx = imageHash(IMAGE_HASH_SEED, recipe.id, NUM_RC_IMAGES)
  const imageSrc = `/images/recipes/ration-pixabay-${imageIdx}.jpg`
  const numAnimals = getTotalNumberOfAnimals(org, recipe)
  const freshMassPerAnimal = getTotalFreshMass(org, recipe)
  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            {/** @todo: Make fav star clickable */}
            <h2>{recipe.name}{/* <FaRegStar />*/}</h2>
            <div className="basic-info">
              {numAnimals} Tiere<br />
              Gewicht: {freshMassPerAnimal.toLocaleString(undefined, { maximumFractionDigits: 1 })} {UNIT_OF_MASS} pro Tier<br />
              Gesamtgewicht: {(numAnimals * freshMassPerAnimal).toLocaleString(undefined, { maximumFractionDigits: 0 })} {UNIT_OF_MASS}
            </div>
          </div>
          <Link to={getLinkToOrgRecipe(org, recipe.id, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Collection className="details" type="list" direction="row" justifyContent="space-between"
          items={[
            {
              title: "Ausladestellen",
              content: <RecipeAusladegruppen org={org} recipeAusladegruppen={recipe.RecipesAusladegruppen} freshMassPerAnimal={freshMassPerAnimal} />
            },
            {
              title: "Komponenten",
              content: <RecipeComponentsList org={org} recipeComponents={recipe.RecipeComponents} />
            }
          ]}>
          {(item, index) => {
            return <Card className="!p-2 !w-1/2" key={item.title}>
              <div className="font-semibold text-[16px] mb-4">{item.title}</div>
              <div className="text-[12px]">
                {item.content}
              </div>
            </Card>
          }}
        </Collection>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
