import { getLinkToVehicleCanTraces, getLinkToVehicleDebug, getLinkToVehicleOverview, getLinkToVehicleReport } from "../LinkUtils"
import './PageHeader.css'

interface VehiclePageHeaderProps {
  vin: string
}
export default function VehiclePageHeader({ vin }: VehiclePageHeaderProps) {
  const vehicleObject = { vin: vin }
  const links = [
    { title: 'Debug', url: getLinkToVehicleDebug(vehicleObject) },
    { title: 'CAN-Traces', url: getLinkToVehicleCanTraces(vehicleObject) },
    { title: 'Bericht', url: getLinkToVehicleReport(vehicleObject) },
    { title: 'Übersicht', url: getLinkToVehicleOverview(vehicleObject) },
  ]
  return <div className="page-header">
    <h1 className='text-xl font-bold mb-3'>Fahrzeug: {vin}</h1>
    <div className="flex flex-row space-x-1">
      {links
        .map(linkInfo => <a key={linkInfo.title} href={linkInfo.url}>{linkInfo.title}</a>)}
    </div>
  </div>
}
