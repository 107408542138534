import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaXmark } from "react-icons/fa6"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { icon } from 'leaflet'

import { Organization } from "sds"

import config, { isProd } from "../../config"
import { UserDataHelper } from "../../api/userData"
import { UserPreferencesContext } from "../../contexts/UserPreferencesContextProvider"
import { CacheKeys } from "../../CacheKeys"
import { UserDataContext, UserDataContextHelper } from "../../contexts/UserDataContextProvider"
import { getLinkToOrg } from "../../LinkUtils"

import Address from "./-components/Address"
import { imageHash } from "./-components/ListItem"
import FeedComponentItem from "./-components/FeedComponentItem"
import RecipeItem from "./-components/RecipeItem"

import 'leaflet/dist/leaflet.css'
import './RecipeManagement.css'

const NUM_ORG_IMAGES = config.images.orgs.numImages
const IMAGE_HASH_SEED = config.images.orgs.imageHashSeed

// The marker icon fix is from https://github.com/PaulLeCam/react-leaflet/issues/808#issuecomment-1605338973
const BslMarkerIcon = icon({
  iconUrl: "/marker-icon.png",
  iconRetinaUrl: "/marker-icon-2x.png",
  shadowUrl: "/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
})

export default function OrgDashboard() {
  const params = useParams()
  const { setUserPreference } = useContext(UserPreferencesContext)
  const { userData } = useContext(UserDataContext)
  // console.log("🧪 OrgDashboard / rootData:", rootData)
  const [org, setOrg] = useState<Organization | undefined>(undefined)

  useEffect(() => {
    console.log("> OrgDashboard.useEffect (setActiveOrgId)")
    if (!org) {
      // console.log("❌ OrgDash.useEffect (setActiveOrgId) org is empty")
      return
    }
    // console.log("OrgDash.useEffect (setActiveOrgId) setting user preference to ", org.id)
    if (!setUserPreference) {
      console.log("❌ setUserPreference is not set.")
    } else {
      setUserPreference(CacheKeys.UserPreferences.ActiveOrgId, org.id)
    }
  }, [org/*, appCtx*/])

  if (!userData) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (rootData) ...</>
  }

  let org_: Organization | undefined = undefined
  if (userData) {
    org_ = UserDataHelper.findOrg(params.orgId!, userData)
  }
  if (!org) {
    setOrg(org_)
  }
  if (!org_ || !org) {
    return isProd
      ? <>Lade Daten ...</>
      : <>Loading (org) ...</>
  }

  const recipes = UserDataContextHelper.findRecipes(org)
  const feedComponents = UserDataContextHelper.findFeedComponents(org)
  const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
  const imageSrc = org.name === "Andreas" || org.name === "Haggeney"
    ? `/images/orgs/hof-pixabay-1.jpg`
    : org.name === "Heiner" || org.name === "Große Börding"
      ? `/images/orgs/hof-pixabay-2.jpg`
      : `/images/orgs/hof-pixabay-${imageIdx}.jpg`
  return <>
    <div className="rm-view-page organization">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{org.name}</h2>
            <div className="basic-info">
              <Address address={org.address} />
            </div>
          </div>
          <Link to={getLinkToOrg(org, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Flex justifyContent="space-between">
          <div className="w-5/12">
            <div className="font-semibold text-[16px] mt-4">Meine Rezepte</div>
            <Collection className="details w-full" type="grid" templateColumns="100%"
              items={recipes}>
              {(item, index) => {
                return <RecipeItem className="!border-none" key={item.id} org={org} item={item} />
              }}
            </Collection>
          </div>

          <div className="w-5/12">
            <div className="font-semibold text-[16px] mt-4">Karte</div>
            {(org.address && typeof (org.address) !== "string") && org.address.gps
              ? (
                <div className='sds-widget map-widget'>
                  <MapContainer center={org.address!.gps} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker icon={BslMarkerIcon} position={{
                      lat: org.address!.gps!.lat,
                      lng: org.address!.gps!.lng
                    }}>
                      <Popup>
                        Standort des Fahrzeugs
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              )
              : (
                <div className="text-[14px]">
                  Ohne Adresse gibt's keine Kartenansicht...
                </div>
              )
            }
          </div>
        </Flex>
      </div>

      <div className="content-overlay mt-6">
        <div className="font-semibold text-[16px] mt-4">Meine Komponenten</div>
        <Collection className="details w-full" type="grid" templateColumns="30% 30% 30%" justifyContent="space-between"
          items={feedComponents}>
          {(item, index) => {
            return <FeedComponentItem className="!border-none" key={item.id} org={org} item={item} />
          }}
        </Collection>
      </div>

      {/* <div className="content-overlay mt-6">
        <Collection className="details" type="list" direction="row"
          items={[
            {
              title: "Statistiken und Fahrer",
              content: <>...<br />
                Platzhalter<br />
                ...</>
            },
          ]}>
          {(item, index) => {
            return <Card key={item.title} className="!p-2 !border !border-gray-400 !rounded-[5px]" style={{ width: "50%" }}>
              <div className="font-semibold text-[16px]">{item.title}</div>
              <div className="text-[12px]">
                {item.content}
              </div>
            </Card>
          }}
        </Collection>
      </div> */}
    </div>

    {/* <small>* Die Icons "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
