import { Button, ButtonVariations } from '@aws-amplify/ui-react';
import './Modal.css';
import { useState } from 'react';

interface ButtonProps {
  variation?: ButtonVariations
  text: string
}

interface ModalProps {
  show: boolean
  buttons: ButtonProps[]
  children: any
  callback: (button: string) => Promise<void>
}
export default function Modal({ show, buttons, children, callback }: ModalProps) {
  const [enabled, setEnabled] = useState(true)
  const buttonClickHandler = async (buttonText: string) => {
    setEnabled(false)
    await callback(buttonText)
    setEnabled(true)
  }

  const showHideClassName = show
    ? "modal block"
    : "modal hidden"

  return <div className={showHideClassName}>
    <section className="modal-main">
      {children}
      <div className='buttons'>
        {buttons.map((b, index) => {
          const props: any = {}
          if (b.variation) {
            props["variation"] = b.variation
          }
          if (!enabled) {
            props["disabled"] = true
          }
          return <Button {...props} type="button" key={`button.${b.text}`} onClick={() => buttonClickHandler(b.text)}>{b.text}</Button>
        })}
      </div>
    </section>
  </div>
}
