import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaRegStar, FaXmark } from "react-icons/fa6"

import { AnimalGroupDto } from "sds"

import config from "../../../config"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { getLinkToOrgAnimalGroup } from "../../../LinkUtils"

import { imageHash } from "../-components/ListItem"
import RecipeItem from "../-components/RecipeItem"
import GenauigkeitPerAnimalGroup from "../-components/GenauigkeitPerAnimalGroup"

import '../RecipeManagement.css'

const NUM_AG_IMAGES = config.images.ag.numImages
const IMAGE_HASH_SEED = config.images.ag.imageHashSeed

export default function AnimalGroup() {
  const params = useParams()

  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const animalGroup: AnimalGroupDto = UserDataContextHelper.findAnimalGroup(org, params.animalGroupId!)

  const recipesFiltered = UserDataContextHelper.findRecipes(org)
    .filter(r => {
      let recipeContainsAg = false
      r.RecipesAusladegruppen.forEach(rag => {
        if (rag.ausladegruppe_id === animalGroup.id) {
          recipeContainsAg = true
          return
        }
      })
      return recipeContainsAg
    })
  const imageIdx = imageHash(IMAGE_HASH_SEED, animalGroup.id, NUM_AG_IMAGES)
  const imageSrc = `/images/ag/animals-pixabay-${imageIdx}.jpg`

  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            {/** @todo: Make fav star clickable */}
            <h2>{animalGroup.name}{/* <FaRegStar />*/}</h2>
            <div className="basic-info">
              {animalGroup.number_of_animals} Tiere
            </div>
          </div>
          <Link to={getLinkToOrgAnimalGroup(org, animalGroup.id, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Flex direction={"row"}>
          <GenauigkeitPerAnimalGroup _className="!w-1/2" animalGroup={animalGroup} />

          <div className="mt-2">
            <div className="font-semibold text-[16px]">Meine Rezepte</div>
            {recipesFiltered.length > 0
              ? (
                < Collection className="details w-full" type="list" direction="column"
                  items={recipesFiltered}>
                  {(item, index) => {
                    return <RecipeItem className="!w-full !border-none" key={item.id} org={org} item={item} hideFavStar={true} hasViewButton={false} />
                  }}
                </Collection>
              )
              : (
                <div className="list-item-grid">
                  <div className="details">
                    {animalGroup.name} wird in keinem Rezept verwendet.
                  </div>
                </div>
              )
            }
          </div>
        </Flex>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
