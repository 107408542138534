import { useContext } from "react"
import { useParams } from "react-router-dom"
import { FaBook } from "react-icons/fa6"

import { Organization, RecipeDto } from "sds"

import config from "../../../config"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"

import { ListPage } from "../-components/ListPage"
import RecipeItem from "../-components/RecipeItem"

import '../RecipeManagement.css'

export function getTotalNumberOfAnimals(org: Organization, recipe: RecipeDto) {
  const totalAnimals = recipe.RecipesAusladegruppen.reduce((prev, cur) => {
    const ag = UserDataContextHelper.findAnimalGroup(org, cur.ausladegruppe_id)
    return prev + ag.number_of_animals
  }, 0)
  return totalAnimals
}

export function getTotalFreshMass(org: Organization, recipe: RecipeDto) {
  let totalFreshMass: number | string = recipe.RecipeComponents.reduce<number>((prev: number, cur) => {
    // Negative values are special error codes. Don't alter them.
    if (prev < 0) {
      return prev
    }
    const fc = UserDataContextHelper.findFeedComponent(org, cur.feed_component_id)
    let curMass = cur.mass_kg
    if (fc.is_dry_mass) {
      if (!fc.ts_gehalt_percent) {
        return -1
      }
      curMass = cur.mass_kg / fc.ts_gehalt_percent as number * 100
    }
    return prev + curMass
  }, 0)
  // if (-1 === totalFreshMass) {
  //   return "Konnte Frischmasse nicht berechnen"
  // }
  return totalFreshMass
}

export default function Recipes() {
  const params = useParams()
  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const recipes = UserDataContextHelper.findRecipes(org)

  return <>
    <ListPage
      _className="recipes"
      icon={<FaBook className="w-5 _h-3.5_ relativ text-[#3E9E29]" />}
      title="Rezepte"
      items={recipes.sort((a, b) => a.name < b.name ? -1 : 1)}
      maxNumItems={config.limits.numberOfRecipes}
      renderFunc={(item: RecipeDto, index: number) => <RecipeItem key={item.id} org={org} item={item} />}
    />

    {/* <small>* Die Icons "book-blank", "sliders-up", "eye (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
