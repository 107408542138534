import { Link, useLoaderData, useLocation, useParams } from "react-router-dom"
import { Button, Flex, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react"
import { FaChartSimple, FaXmark } from "react-icons/fa6"

import { PostFeedingReportMessageDto } from "sds"

import { CURRENCY_SYMBOL, UNIT_OF_MASS } from "../../../org-settings"

export default function FeedingDetails() {
  const params = useParams()
  const location = useLocation()
  // const orgId = params.orgId
  const feedingReportId = params.feedingReportId + location.hash
  const feedingReport = useLoaderData() as PostFeedingReportMessageDto
  console.log("feedingReport = ", feedingReport)
  const feeding = feedingReport.data
  console.log("feeding = ", feeding)
  const icon = <FaChartSimple className="w-5 _h-3.5_ relativ text-[#3E9E29]" />
  const title = `Fütterung ${feedingReportId}`
  const _className = "feeding"

  const duration_ms = new Date(feeding.Endzeit).getTime() - new Date(feeding.Startzeit).getTime()
  const duration_min = duration_ms / 1000 / 60

  const totalNumberOfAnimals = feeding.Ausladegruppen.reduce((prev, curr) => { return prev + curr.Tieranzahl }, 0)
  const totalMass = feeding.Komponenten.reduce((prev, curr) => { return prev + curr.Menge }, 0)
  const massPerAnimal = totalMass / totalNumberOfAnimals

  return <div className={`rm-list-page ${_className}`}>
    <Flex className="!h-12 !pb-2 !justify-between !items-center">
      <Flex className="!h-6 !justify-start !items-center gap-3">
        {icon}
        <div className="text-xl font-semibold">{title}</div>
      </Flex>

      <Flex className="!justify-center !items-center gap-2.5">
        <Link to='..'>
          <Button className="button-close has-icon !bg-white" style={{ boxShadow: "0px 1px 4px #15223214" }}><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
          {/* @todo: background of 'X' */}
        </Link>
      </Flex>
    </Flex>

    <div className="rm-list-container">
      <Table highlightOnHover={true} size="small" variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Rezeptname</TableCell>
            <TableCell>{feeding.Bezeichnung}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">Start, Ende</TableCell>
            <TableCell>
              {new Date(feeding.Startzeit).toLocaleDateString('de-de')}
              {' '}
              {new Date(feeding.Startzeit).toLocaleTimeString('de-de')}
              {' - '}
              {new Date(feeding.Endzeit).toLocaleTimeString('de-de')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">Arb. Zeit</TableCell>
            <TableCell>{duration_min.toLocaleString(undefined, { maximumFractionDigits: 0 })} Min.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell as="th">Fahrer</TableCell>
            <TableCell>{feeding.Fahrer}</TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <div className="mt-6 mb-4">Ladung</div>
      <Table highlightOnHover={true} size="small" variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Futtermittel</TableCell>
            <TableCell as="th">Geplant</TableCell>
            <TableCell as="th">Ladung</TableCell>
            <TableCell as="th">Abweichung</TableCell>
            <TableCell as="th">Kosten</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {feeding.AufgenMeng
            .map(ld => {
              console.log(ld)
              const fc = feeding.Komponenten.find(fc => fc.id === ld.id)!
              const error =( (ld.Menge / fc.Menge) -1)*100
              const cost = 0
              return <TableRow>
                <TableCell>{ld.Bezeichnung}</TableCell>
                <TableCell>{fc.Menge.toLocaleString(undefined, { maximumFractionDigits: 1 })} {UNIT_OF_MASS}</TableCell>
                <TableCell>{ld.Menge.toLocaleString(undefined, { maximumFractionDigits: 1 })} {UNIT_OF_MASS}</TableCell>
                <TableCell>
                  {error >= 0 && <span style={{ visibility: "hidden", opacity: 0 }}>-</span>}
                  {error.toLocaleString(undefined, { maximumFractionDigits: 2 })} %
                </TableCell>
                <TableCell>{cost.toLocaleString(undefined, { maximumFractionDigits: 0 })} {CURRENCY_SYMBOL}</TableCell>
              </TableRow>
            })}
        </TableBody>
      </Table>

      <div className="mt-6 mb-4">Abladung</div>
      <Table highlightOnHover={true} size="small" variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Ausladegruppe</TableCell>
            <TableCell as="th">Tiere</TableCell>
            <TableCell as="th">Geplant</TableCell>
            <TableCell as="th">Abladung</TableCell>
            <TableCell as="th">Genauigkeit</TableCell>
            {/* <TableCell as="th">Kosten</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {feeding.AusgebrMeng
            .map(unl => {
              console.log(unl)
              const ag = feeding.Ausladegruppen.find(ag => ag.id === unl.id)!
              const unloadingTarget = massPerAnimal * ag.Tieranzahl
              const error =( (unl.Menge / unloadingTarget) -1)*100
              // const error = 0
              return <TableRow>
                <TableCell>{ag.Bezeichnung}</TableCell>
                <TableCell>{ag.Tieranzahl}</TableCell>
                <TableCell>{unloadingTarget.toLocaleString(undefined, { maximumFractionDigits: 1 })} {UNIT_OF_MASS}</TableCell>
                <TableCell>{unl.Menge.toLocaleString(undefined, { maximumFractionDigits: 1 })} {UNIT_OF_MASS}</TableCell>
                <TableCell>
                  {error >= 0 && <span style={{ visibility: "hidden", opacity: 0 }}>-</span>}
                  {error.toLocaleString(undefined, { maximumFractionDigits: 2 })} %
                </TableCell>
                {/* <TableCell>{lc.Cost.toLocaleString(undefined, { maximumFractionDigits: 0 })} {CURRENCY_SYMBOL}</TableCell> */}
              </TableRow>
            })}
        </TableBody>
      </Table>
    </div>
  </div >
}
