import { useContext } from 'react'
import { FaHouse } from 'react-icons/fa6'

import { Organization } from 'sds'

import config from "../../config"
import { UserDataContext, UserDataContextHelper } from '../../contexts/UserDataContextProvider'
import { getLinkToOrg } from '../../LinkUtils'
import Address from './-components/Address'
import { imageHash, ListItem } from './-components/ListItem'
import { ListPage } from './-components/ListPage'

const NUM_ORG_IMAGES = config.images.orgs.numImages
const IMAGE_HASH_SEED = config.images.orgs.imageHashSeed

export default function Orgs() {
  const { userData } = useContext(UserDataContext)
  const orgs = UserDataContextHelper.findOrgs(userData!)

  const renderItem = (item: any, index: number): React.ReactElement => {
    const org = item as Organization
    const imageIdx = imageHash(IMAGE_HASH_SEED, org.id, NUM_ORG_IMAGES)
    const imageSrc = org.name === "Andreas" || org.name === "Haggeney"
      ? `/images/orgs/hof-pixabay-1.jpg`
      : org.name === "Heiner" || org.name === "Große Börding"
        ? `/images/orgs/hof-pixabay-2.jpg`
        : `/images/orgs/hof-pixabay-${imageIdx}.jpg`
    return <ListItem
      key={org.id}
      className="org"
      title={org.name}
      href={getLinkToOrg(org, false)}
      imageSrc={imageSrc}
      details={<Address address={org.address} />}
      hasEditButton={true} />
  }

  return <>
    <ListPage
      _className='orgs'
      icon={<FaHouse className="w-5 _h-3.5_ relativ text-[#3E9E29]" />}
      title='Meine Betriebe'
      items={orgs.sort((a, b) => a.name < b.name ? -1 : 1)}
      maxNumItems={config.limits.numberOfOrgs}
      renderFunc={renderItem}
    />

    {/* <small>
      * Die Icons "house-blank", "sliders-up", "eye (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.<br />
    </small> */}
  </>
}
