/** Extracts the date part from an ISO datetime string. */
export const dateOnly = (dateAndTimeIso: string): string => dateAndTimeIso.split('T')[0]

/** Today's date as ISO date string. */
export const getToday = (): string => dateOnly(new Date().toISOString())

/** Parses a timestamp value into a date.
 * 
 * If the value is a number or a number as string, then it must be a unix timestamp since epoch, UTC.
 * Could be seconds (10-digit integer), seconds and fractions thereof (float), or milliseconds (13-digit integer) precision.
 * 
 * If it is any other string, then it must be ISO-format, UTC.
 */
export function parseTimestamp(timestamp: number | string): Date {
  const timestampNum = Number(timestamp)
  if (timestampNum) {
    const timestampNumMs = (timestampNum < 1000000000000)
      ? timestampNum * 1000
      : (timestampNum > 9999999999999)
        ? timestampNum / 1000
        : timestampNum

    return new Date(timestampNumMs)
  }
  return new Date(timestamp)
}

/** Converts a datetime string (UTC) into a local, German datetime */
export const toLocalStringDe = (datestr_utc: string) => {
  return new Date(datestr_utc).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

/** Converts a duration in seconds into a human-readable (German) string */
export function secondsToString(seconds: number) {
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400)
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
  const yearsStr = numyears ? numyears + " Jahre" : ''
  const daysStr = numdays ? numdays + " Tage" : ''
  const hoursStr = numhours < 10 ? '0' + numhours : numhours
  const minsStr = numminutes < 10 ? '0' + numminutes : numminutes
  const secsStr = numseconds < 10 ? '0' + numseconds : numseconds
  const hmsStr = `${hoursStr}:${minsStr}:${secsStr} Stunden`
  return [yearsStr, daysStr, hmsStr].join(' ')
}
