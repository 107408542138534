export const isDev = process.env.NODE_ENV
  .toLowerCase()
  .startsWith('dev')
export const isProd = process.env.NODE_ENV
  .toLowerCase()
  .startsWith('prod')

const url_dev = 'https://ffvovqifi8.execute-api.eu-central-1.amazonaws.com'
const url_prod = 'https://mh7hxqx3fb.execute-api.eu-central-1.amazonaws.com'

const getApiUrl = () => {
  if (isDev) {
    console.log('Using dev API:', url_dev)
    return url_dev
  }
  return url_prod
}

const frontendApiUrl_dev = 'https://8jb034cvsl.execute-api.eu-central-1.amazonaws.com'
const frontendApiUrl_prod = 'https://uyxhwhccac.execute-api.eu-central-1.amazonaws.com'

const getFrontendApiUrl = () => {
  if (isDev) {
    console.log('Using dev API (fe):', frontendApiUrl_dev)
    return frontendApiUrl_dev
  }
  return frontendApiUrl_prod
}

export const config = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: getApiUrl()
  },
  frontendApi: {
    REGION: 'eu-central-1',
    URL: getFrontendApiUrl()
  },
  limits: {
    numberOfOrgs: 5,
    numberOfRecipes: 30,
    numberOfFeedComponents: 40,
    numberOfFeedComponentsPerRecipe: 20,
    numberOfAnimalGroups: 20,
    numberOfAnimalGroupsPerRecipe: 20, // same as `numberOfAnimalGroups`
  },
  images: {
    ag: {
      numImages: 5,
      imageHashSeed: 271,
    },
    fc: undefined, // FCs have selectable images.
    orgs: {
      numImages: 3,
      imageHashSeed: 273,
    },
    rc: {
      numImages: 5,
      imageHashSeed: 27,
    },
  },
}

export default config