import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import AuthenticatorHeader from "../components/AuthenticatorHeader"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"

const components = {
  Header: AuthenticatorHeader
}

export default function Login() {
  console.log("> Login")
  const { route } = useAuthenticator(context => [context.route])
  // console.log("  Login: Auth.route =", route)
  const location = useLocation()
  const navigate = useNavigate()
  let prevUrl = location.state?.from?.pathname
  if (!prevUrl || prevUrl.startsWith('/login')) {
    prevUrl = '/'
  }
  // console.log("  Login: prevUrl =", prevUrl)
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(prevUrl, {
        state: { from: location },
        replace: true
      })
    }
  }, [location, navigate, prevUrl, route])
  return <Authenticator components={components} />
}

export const Component = Login
