import { FmiValues, LampStatus } from 'j1939'

/** Resolves an enum value to the corresponding key. */
const resolveEnumValueToKey = (enumObject: any, value: number): string | null => {
    for (const key in enumObject) {
        if (enumObject[key as keyof typeof enumObject] === value) {
            return key
        }
    }
    return null
}

export const resolveFmiValue = (fmiValue: number): string | null => resolveEnumValueToKey(FmiValues, fmiValue)
export const resolveLampStatus = (lampStatus: number): string | null => resolveEnumValueToKey(LampStatus, lampStatus)
