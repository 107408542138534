import { array, InferType, object, string } from "yup"
import { OrganizationSchema } from "./recipes/Organization"

export const UserDataSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  email: string().required(),
  first_name: string(),
  id: string().required(),
  last_name: string(),
  // preferences: Json | null
  updated_at: string().defined(),
  updated_by: string().defined(),

  Organizations: array(OrganizationSchema).required()
})

export type UserDataDto = InferType<typeof UserDataSchema>
