import { BatchWriteCommandInput, BatchWriteCommand, DynamoDBDocumentClient, PutCommand, PutCommandInput } from "@aws-sdk/lib-dynamodb"
import { HasPkSk } from "../../../@types/sds/"
import { getErrorMessage } from './Utils.Errors'

/**
 * If a LatestItem's `Value` is unchanged, updates will be throttled.
 * @see LATEST_ITEM_THROTTLE_EXPIRES_S defines the duration after which an item will always be updated, even if unchanged.
 */
export const LATEST_ITEM_THROTTLE_EXPIRES_S = 60

/** Splits a list into batches.
 * @param data 
 * @param batchSize 
 * @returns A list of lists. Each but the last one are of `batchSize` length, the last one may be shorter.
 */
export function batchItems(data: HasPkSk[], batchSize: number = 25) {
  const listOflists: HasPkSk[][] = []
  let currentList: HasPkSk[] = []
  data.forEach((value) => {
    if (currentList.length >= batchSize) {
      listOflists.push(currentList)
      currentList = []
    }
    currentList.push(value)
  })
  if (currentList.length) {
    listOflists.push(currentList)
  }
  return listOflists
}

/** Helper function that stores items in a ddb table in batches.
 * 
 * Uses BatchWrite to reduce the number of ddb requests.
 * Does not support Conditions.
 * Uses a fixed batch size of 25 (max. of ddb).
 * 
 * @param items The items, which must satisfy the table's key schema. Raw JS items, not marshalled to ddb objects with `AttributeValue`s.
 * @param tableName The table name.
 * @param ddbDocClient A ddb document client.
 */
const batchStoreItemsInDdb = async (items: HasPkSk[], tableName: string, ddbDocClient: DynamoDBDocumentClient) => {
  console.debug(`> batchStoreItemsInDdb: ${items.length} items;  ${tableName}`)
  const promises = batchItems(items, 25).map(batch => {
    const itemRequests = {
      [tableName]: batch.map((value) => ({
        PutRequest: { Item: value }
      }))
    }
    const input: BatchWriteCommandInput = {
      RequestItems: itemRequests,
      // ReturnConsumedCapacity: 'TOTAL'
    }
    // console.debug('input', JSON.stringify(input, null, '  '))
    return ddbDocClient.send(new BatchWriteCommand(input))
  })
  /** @todo Handle ProvisionedThroughputExceededException */
  return Promise.all(promises)
}

/** Stores items in a ddb table.
 * @param historicItems Items that should be stored in any case.
 * @param latestItems Items that should only stored if no such item exists, or if the existing item is older.
 * @param ddbDocClient A ddb document client.
 * @returns The number of {@linkcode latestItems} where the Condition failed.
 */
export const storeItemsInDdb = async (tableName: string, historicItems: HasPkSk[], latestItems: HasPkSk[], ddbDocClient: DynamoDBDocumentClient): Promise<number> => {
  console.debug(`> storeItemsInDdb: ${tableName}`)

  console.debug(`Begin storing ${latestItems.length} latest items, conditionally and async`)
  let numFailedConditions = 0
  // Note: Promise.all has Fail-fast. Don't want!
  const allSettledPromises = Promise.allSettled(latestItems.map(value => {
    const input: PutCommandInput = {
      TableName: tableName,
      Item: value,
      // no such item exists,
      // OR the item has no `Value` property AND the existing item is older than the data point
      // OR the item's `Value` property is not numeric AND the existing item is older than the data point
      // OR the data point and item have different `Value` properties AND the existing item is older than the data point
      // OR the existing item is much older than the data point
      ConditionExpression: 'attribute_not_exists(PK)'
        + ' OR (attribute_not_exists(#value) AND :datapointTimestamp > #ts)'
        + ' OR (attribute_exists(#value) AND :datapointTimestamp > #ts AND NOT attribute_type(#value, :numberType))'
        + ' OR (attribute_exists(#value) AND :datapointTimestamp > #ts AND attribute_type(#value, :numberType) AND :datapointValue <> #value)'
        + ' OR (:datapointTimestampOffset > #ts)',
      ExpressionAttributeValues: {
        ':datapointTimestamp': value.Timestamp as number,
        ':datapointTimestampOffset': value.Timestamp as number - (LATEST_ITEM_THROTTLE_EXPIRES_S * 1000), // 60 seconds in the past
        ':datapointValue': value.Value as number || null,
        ':numberType': "N"
      },
      ExpressionAttributeNames: {
        '#ts': 'Timestamp',
        '#value': 'Value'
      },
      ReturnValues: 'ALL_OLD',
      ReturnValuesOnConditionCheckFailure: "ALL_OLD",
      // ReturnConsumedCapacity: 'TOTAL'
    }
    return ddbDocClient.send(new PutCommand(input))
      .catch(err => {
        if (err.name === 'ConditionalCheckFailedException') {
          console.warn('Put command condition failed for', value.SK)
          console.warn(err)
          // Returned old item, in case you wanted to check: err.Item
          console.warn("Existing item:", err.Item)
          console.warn("New item:", input.Item)
          numFailedConditions++
        } else {
          console.log(JSON.stringify(err, null, 2))
          const errMsg = getErrorMessage(err)
          console.error('Handling put command error for', value.SK, ':', errMsg)
          console.error('Input:', JSON.stringify(input.Item, null, 2))
        }
      })
    /** @todo Handle ProvisionedThroughputExceededException */
  }))

  console.debug(`Storing ${historicItems.length} historic items, batched`)
  await batchStoreItemsInDdb(historicItems, tableName, ddbDocClient)
  console.debug('Done storing historic items')

  const results = await allSettledPromises
  // Note: No need to evaluate the `results`. Due to the `.catch()` above, every promise is fulfilled. Counting the `.catch()` works.
  //       Alternatively, the `.catch()` could be removed, the `results` investigated, and the results with `status` "rejected" counted.
  console.debug('Done storing latest items')

  return numFailedConditions
}
