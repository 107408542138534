import { Card } from "@aws-amplify/ui-react"
import { FaRegStar, FaStar } from "react-icons/fa6"
import { ChartData, ChartDataset, Chart as ChartJS } from "chart.js"
import 'chartjs-adapter-luxon'
import { Line } from "react-chartjs-2"
import annotationPlugin from 'chartjs-plugin-annotation';

import { STATS_greenAnnotationHalfWidth, STATS_yellowAnnotationHalfWidth } from "../../../org-settings"
import { StarStates } from "./ListItem"

ChartJS.register(
  annotationPlugin
)

// Farben die man haben möchte
// https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
const colorPalette = [
  '#87bc45', '#27aeef', '#ef9b20', '#b33dc6', '#f46a9b'
]

// Farben auswählen (Offset verwenden, wenn man nicht dieselben Farben in beiden Charts will)
export function getPersistentColor(index: number, colorPaletteStartingIndex = 0) {
  return colorPalette[(index + colorPaletteStartingIndex) % colorPalette.length]
}

export function prepareAccuracyDatasets(chartData: {
  [entryId: string]: {
    feedComponentId?: string,
    animalGroupId?: string,
    label: string,
    xData: string[],
    yData: number[],
  }
}) {
  // console.log(chartData)
  const mergedData: { [feedComponentId: string]: PointInTime[] } = {}
  const ownProps = Object.keys(chartData).filter(propName => Object.hasOwn(chartData, propName))
  ownProps.forEach(feedComponentId => {
    const entry = chartData[feedComponentId]
    const seriesX = entry.xData
    const seriesY = entry.yData
    const seriesMergedXY: PointInTime[] = []
    for (var i = 0; i < seriesX.length; i++) {
      seriesMergedXY.push({
        x: seriesX[i],
        y: seriesY[i]
      })
    }
    mergedData[feedComponentId] = seriesMergedXY
  })

  const datasets: ChartData<'line', PointInTime[]> = {
    datasets: ownProps.map((feedComponentId, index) => ({
      label: chartData[feedComponentId].label,
      data: mergedData[feedComponentId],
      borderColor: getPersistentColor(index),
      tension: 0.05,
      pointBackgroundColor: getPersistentColor(index),
    })) as ChartDataset<'line', PointInTime[]>[]
  }

  return datasets
}

export function getAnnotationSettings() {
  /** @todo: In the future, get values from org-settings. */
  const greenAnnotationHalfWidth = STATS_greenAnnotationHalfWidth
  const yellowAnnotationHalfWidth = STATS_yellowAnnotationHalfWidth
  const greenAnnotationMin = 100 - greenAnnotationHalfWidth
  const greenAnnotationMax = 100 + greenAnnotationHalfWidth
  const yellowUpperAnnotationMin = greenAnnotationMax
  const yellowUpperAnnotationMax = yellowUpperAnnotationMin + yellowAnnotationHalfWidth
  const yellowLowerAnnotationMax = greenAnnotationMin
  const yellowLowerAnnotationMin = yellowLowerAnnotationMax - yellowAnnotationHalfWidth
  const greenAnnotationColor = 'rgba(135, 188, 69, 0.25)'
  const yellowAnnotationColor = 'rgba(208, 208, 39, 0.25)'
  return {
    yellowUpper: {
      min: yellowUpperAnnotationMin,
      max: yellowUpperAnnotationMax,
      color: yellowAnnotationColor,
    },
    green: {
      min: greenAnnotationMin,
      max: greenAnnotationMax,
      color: greenAnnotationColor,
    },
    yellowLower: {
      min: yellowLowerAnnotationMin,
      max: yellowLowerAnnotationMax,
      color: yellowAnnotationColor,
    },
  }
}

// Timestamp für x-Achse formatieren
function formatDate(dateString: string, showTime: boolean = false) {
  const date = new Date(dateString);
  if (showTime) {
    return date.toISOString().slice(0, 16).replace('T', ' ');
  } else {
    return date.toISOString().slice(0, 10);
  }
}

export type PointInTime = { x: string, y: number }
