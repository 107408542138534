import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { icon } from 'leaflet'

import { LocationDatapoint } from 'j1939'

import 'leaflet/dist/leaflet.css'
import './Widgets.css'

interface WidgetWithTitleProps {
  title: string
}

interface BooleanTextWidgetProps extends WidgetWithTitleProps {
  value: boolean
  trueText?: string
  falseText?: string
}
export function BooleanTextWidget({ title, value, trueText = 'Ja', falseText = 'Nein' }: BooleanTextWidgetProps) {
  return <div className='sds-widget boolean-text-widget'>
    <div className='title'>{title}</div>
    <div className='value'>{value ? trueText : falseText}</div>
  </div>
}

interface NumberWidgetProps extends WidgetWithTitleProps {
  value: number | string
  unit?: string
}
export function NumberWidget({ title, value, unit }: NumberWidgetProps) {
  if (typeof (value) === 'string') {
    value = parseFloat(value)
  }
  return <div className='sds-widget number-widget'>
    <div className='title'>{title}</div>
    <div><span className='value'>{value}</span>{unit ? <>{' '}<span className='unit'>{unit}</span></> : ''}</div>
  </div>
}

// The marker icon fix is from https://github.com/PaulLeCam/react-leaflet/issues/808#issuecomment-1605338973
const BslMarkerIcon = icon({
  iconUrl: "/marker-icon.png",
  iconRetinaUrl: "/marker-icon-2x.png",
  shadowUrl: "/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
})
interface MapWidgetProps extends WidgetWithTitleProps {
  location: LocationDatapoint
}
export function MapWidget({ location, title }: MapWidgetProps) {
  const position = {
    lat: location.Latitude,
    lng: location.Longitude
  }
  return (
    <div className='sds-widget map-widget'>
      <div className='title'>{title}</div>
      <div className='value'>
        <MapContainer center={position} zoom={13} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker icon={BslMarkerIcon} position={position}>
            <Popup>
              Standort des Fahrzeugs
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  )
}
