/**
 * Mapping between Recipes and Ausladegruppen.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 * @todo Rename to `RecipeAnimalGroupsSchema`
 * @todo Can the `AnimalGroupSchema` be reused?
 */

import { InferType, number, object, string } from "yup"

export const RecipesAusladegruppenSchema = object({
  ausladegruppe_id: string().required(),
  order: number().positive(),
  recipe_id: string().required(),
})

export type RecipesAusladegruppen = InferType<typeof RecipesAusladegruppenSchema>
