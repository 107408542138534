interface AddressProps {
  address: any
}
export default function Address({ address }: AddressProps) {
  if (!address) {
    address = {
      street: "Straße",
      number: "1",
      zip: "12345",
      city: "Ort"
    }
  }
  return <div>
    {address.street} {address.number}<br />
    {address.zip} {address.city}
  </div>
}
