import { LoaderFunction } from "react-router-dom"
import { getCurrentUser } from "aws-amplify/auth"
import { Cache } from 'aws-amplify/utils'

import { isProd } from "../config"

import { fetchFromApi, getCacheKey } from "./utils"

// 10 minutes
const EXPIRES_IN_MS = 10 * 60 * 1000

export const getFeedingReport = async (orgId: string, feedingReportId: string): Promise<any | null> => {
  console.log("> getFeedingReport")
  const apiName = 'frontendApi'
  const path = `/feeding-reports/${orgId}/${feedingReportId}`
  const cacheKey = getCacheKey(apiName, path)

  // Only load if the user is signed in.
  try {
    const currentUser = await getCurrentUser()
    // console.log("  got signed in user:", currentUser)
  } catch (err) {
    // console.log("  cannot load FeedingReport data: No signed in user.")
    if (!isProd) {
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
    // console.log("  no signed in user:", err)
    // console.log("❌ getFeedingReport: RETURN NULL")
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path)
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getFeedingReport: data")
    return await data
  }
  catch (err) {
    console.error('Error fetching FeedingReport data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getFeedingReport: throw")
    throw err
  }
}

const loadFeedingReport = async (orgId: string, feedingReportId: string): Promise<any | undefined> => {
  console.log("> loadFeedingReport")
  const feedingReport = await getFeedingReport(orgId, feedingReportId)
  if (!feedingReport) {
    console.log("  😕 got no FeedingReport data")
    return undefined
  }
  // console.log("  🎉 got FeedingReport data")
  console.log("< loadFeedingReport")
  return feedingReport
}

export const loader: LoaderFunction = async ({ params }): Promise<any | null> => {
  console.log("> FeedingReportsApi.loader")
  if (!params.orgId) {
    console.log("  param orgId is missing")
    return null
  }
  const orgId = params.orgId
  if (!params.feedingReportId) {
    console.log("  param feedingReportId is missing")
    return null
  }
  const feedingReportId = params.feedingReportId
  console.debug("  feedingReportId = ", feedingReportId)
  console.debug("  orgId = ", orgId)

  const feedingReport = await loadFeedingReport(orgId, feedingReportId)
  if (!feedingReport) {
    return null
  }
  return feedingReport
}
