import { useContext, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Collection, Flex } from "@aws-amplify/ui-react"
import { FaPenToSquare, FaRegStar, FaXmark } from "react-icons/fa6"

import { FeedComponentDto } from "sds"

import { CURRENCY_SYMBOL, UNIT_OF_LARGE_MASS } from "../../../org-settings"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { getLinkToOrgFeedComponent } from "../../../LinkUtils"

import RecipeItem from "../-components/RecipeItem"
import GenauigkeitPerFeedComponent from "../-components/GenauigkeitPerFeedComponent"
import VerbrauchPerFeedComponent from "../-components/VerbrauchPerFeedComponent"
import { ensureImagePath } from "../-components/FeedComponentUtils"

import '../RecipeManagement.css'

export default function FeedComponent() {
  const params = useParams()
  const [bgImageSrc, setBgImageSrc] = useState<string>("")

  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const feedComponent: FeedComponentDto = UserDataContextHelper.findFeedComponent(org, params.feedComponentId!)

  const recipesFiltered = UserDataContextHelper.findRecipes(org)
    .filter(r => {
      let recipeContainsFc = false
      r.RecipeComponents.forEach(rfc => {
        if (rfc.feed_component_id === feedComponent.id) {
          recipeContainsFc = true
          return
        }
      })
      return recipeContainsFc
    })

  ensureImagePath(feedComponent, bgImageSrc, setBgImageSrc)

  return <>
    <div className="rm-view-page">
      <div className="hdr-img" style={{
        background: `transparent url('${bgImageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <Link to='..'>
        <Button className="button-close has-icon"><span role="img" aria-label="Schließen"><FaXmark /></span></Button>
      </Link>
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{feedComponent.name}</h2>
            <div className="basic-info">
              <div>
                TS-Gehalt: {feedComponent.ts_gehalt_percent
                  ? `${feedComponent.ts_gehalt_percent.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`
                  : 'nicht angegeben'}
              </div>
              <div>
                Preis: {feedComponent.price_per_kg
                  ? `${feedComponent.price_per_kg} ${CURRENCY_SYMBOL} / ${UNIT_OF_LARGE_MASS} FM`
                  : 'nicht angegeben'}
              </div>
              {/* <div>
                Währung: {feedComponent.currency || "n/a"}
              </div>
              <div>
                Gruppe: {feedComponent.group || "n/a"}
              </div> */}
            </div>
          </div>
          <Link to={getLinkToOrgFeedComponent(org, feedComponent.id, true)}>
            <Button className="button-edit has-icon"><span role="img" aria-label="Bearbeiten"><FaPenToSquare /></span></Button>
          </Link>
        </div>

        <Flex direction={"row"}>
          <div className="mt-2 w-1/3">
            <div className="font-semibold text-[16px]">Meine Rezepte</div>
            {recipesFiltered.length > 0
              ? (
                <Collection className="details w-full" type="list" direction="column"
                  items={recipesFiltered}>
                  {(item, index) => {
                    return <RecipeItem className="!w-full !border-none" key={item.id} org={org} item={item} hideFavStar={true} hasViewButton={false} />
                  }}
                </Collection>
              )
              : (
                <div className="list-item-grid">
                  <div className="details">
                    {feedComponent.name} wird in keinem Rezept verwendet.
                  </div>
                </div>
              )
            }
          </div>

          <VerbrauchPerFeedComponent _className="!w-1/3" feedComponent={feedComponent} />
          <GenauigkeitPerFeedComponent _className="!w-1/3" feedComponent={feedComponent} />
        </Flex>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
