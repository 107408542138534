import { get } from 'aws-amplify/api'
import { Cache } from 'aws-amplify/utils'
import { ScrollView } from '@aws-amplify/ui-react'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import { ScanCommandOutput } from "@aws-sdk/lib-dynamodb"

import { currentSession } from '../../Utils.AmplifyAuth';
import { isDev } from '../../config'
import { DevicesTable } from '../DevicesTable'
import { Device } from '../../../../../@types/sds/'

import './VehiclesList.css'

// TODO: Move into a DataLoader / Repository class
export const loader: LoaderFunction = async (args): Promise<Device[]> => {
  // 10 minutes
  const EXPIRES_IN_MS = 10 * 60 * 1000
  const apiName = 'vehiclesApi'
  const path = '/vehicles'
  const cacheKey = `${apiName}#${path}`

  const fetchFromApi = async () => {
    console.log(cacheKey, 'not found in cache. Loading from API...')
    const tokens = await currentSession()
    const options = {
      headers: {
        Authorization: `Bearer ${tokens?.idToken!}`
      }
    }
    const restOperation = get({ apiName, path, options })
    const response = await (await restOperation.response).body.json() as unknown as ScanCommandOutput
    // console.log(response)
    return response
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data: Device[] = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      const response = await fetchFromApi()
      data = response.Items!.map(elem => {
        elem.vin = elem.ext_sn
        elem.modem_sn = elem.seriennummer
        return elem
      })
      const aBeforeB = -1
      const bBeforeA = 1
      data.sort((a, b) => {
        /** Sort obsolete entries to the end of the list */
        const obsoleteIndicators = ["kahu-", "evnet-", "_old", "_alt"]
        const aSeriennummer = (a.seriennummer as string + "#" + a.ext_sn as string).toLocaleLowerCase()
        const bSeriennummer = (b.seriennummer as string + "#" + b.ext_sn as string).toLocaleLowerCase()
        const isAObsolete = obsoleteIndicators.some((indicator) => aSeriennummer.includes(indicator))
        const isBObsolete = obsoleteIndicators.some((indicator) => bSeriennummer.includes(indicator))
        if (isAObsolete && !isBObsolete) {
          return bBeforeA
        }
        if (!isAObsolete && isBObsolete) {
          return aBeforeB
        }
        /** Sort machines with real VIN to the top of the list */
        const aHasRealVin = (a.m_fahrgestellnummer as string).startsWith("W")
        const bHasRealVin = (b.m_fahrgestellnummer as string).startsWith("W")
        if (aHasRealVin && !bHasRealVin) {
          return aBeforeB
        }
        if (!aHasRealVin && bHasRealVin) {
          return bBeforeA
        }
        return 0
      })
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }

    return data
  }
  catch (err) {
    console.error('Error fetching data from api')
    if (isDev) {
      console.error(`Tried to fetch VehiclesList data from ${apiName} :: ${path}`)
    }
    console.log(err)
    throw err
  }
}

interface VehiclesListProps {
  singleRow: boolean
}
export default function VehiclesList({ singleRow }: VehiclesListProps) {
  // const [searchParams, setSearchParams] = useSearchParams({ replace: true })
  const [searchParams] = useSearchParams()
  const data = useLoaderData() as Device[]

  const vehiclesFiltered = data.filter(vehicle => {
    const filter = searchParams.get('filter')
    if (!filter) return true
    const haystack = [vehicle.vin, vehicle.modem_sn, vehicle.m_machineType, vehicle.adresse?.strasse, vehicle.adresse?.ort, vehicle.adresse?.bezeichnung].join('#').toLowerCase()
    return haystack.includes(filter.toLowerCase())
  })

  if (vehiclesFiltered.length < 1) {
    return <div>Keine Treffer. Versuchen Sie andere Filterkriterien.</div>
  }

  return <ScrollView>
    <DevicesTable
      headings={["VIN", "Typ", "Status", "Modem"]}
      fields={["vin", "m_machineType", "connection_status", "modem_sn"]}
      devices={vehiclesFiltered}
      singleRow={singleRow} />
    {/* <DevicesTable
            headings={["Modem", "Typ", "Status", "Standort", "VIN"]}
            fields={["modem_sn", "type", "connection_status", "address", "vin"]}
            devices={vehiclesFiltered} /> */}
  </ScrollView>
}
