/**
 * Properties of a Recipe.
 * 
 * @note The interfaces in this file are more or less copied from the supabase generated types in the users-svc.
 */

import { Flags, InferType, ValidationError, addMethod, array, object, string } from "yup"

import { RecipeComponent, RecipeComponentSchema, RecipesAusladegruppen, RecipesAusladegruppenSchema } from "."

// Add a `unique` method to arrays
// From: https://github.com/jquense/yup/issues/345
addMethod(array, 'uniqueProperty', function (
  propertyName: string,
  message: string = '${path} may not have duplicates'
) {
  return this.test('uniqueProperty', message, (list: any[] | undefined, context) => {
    // console.log("🔬 evaluating unique constraint on ", list)
    if (!list) {
      return true
    }
    const errors: ValidationError[] = []
    const duplicateProperties = list
      .filter((e, i) => list.findIndex((e2) => e2[propertyName] === e[propertyName]) !== i)
      .map((e) => e[propertyName])
    for (let i = 0; i < list.length; i += 1) {
      const element = list[i]
      if (element[propertyName] !== "" && duplicateProperties.includes(element[propertyName])) {
        errors.push(new ValidationError(message, element, `${context.path}[${i}].${propertyName}`))
      }
    }
    if (errors.length > 0) {
      // console.log("❌❌❌", errors)
      return context.createError({ message: () => errors })
    }

    return true
  })
})

// Declare the `uniqueProperty` method, so TS knows
// From: https://github.com/jquense/yup/issues/345
declare module 'yup' {
  interface ArraySchema<TIn extends any[] | null | undefined, TContext, TDefault = undefined, TFlags extends Flags = ''> {
    uniqueProperty(propertyName: string, message?: string): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }
}

const CreatedPropsSchema = object({
  created_at: string().required(),
  created_by: string().required()
})
type CreatedProps = InferType<typeof CreatedPropsSchema>
type CreatedPropsKeys = keyof CreatedProps
const UpdatedPropsSchema = object({
  updated_at: string().required(),
  updated_by: string().required()
})
type UpdatedProps = InferType<typeof UpdatedPropsSchema>
type UpdatedPropsKeys = keyof UpdatedProps

/**
 * Summary of the schemas/types and their differences
 *   - Recipe[Schema] - a full recipe (from a schema)
 *   - NewRecipe[Schema] - for creation of a new recipe (from a schema)
 *   - MyNewRecipeSchema - for creation of a new recipe (manual)
 *   - UpdatedRecipeSchema - for updating a recipe (from a schema)
 *     - there is no `UpdatedRecipe` type in this file
 */
export const NewRecipeSchema = object({
  // created_at: string(),
  // created_by: string(),
  // id: string(),
  name: string().required(),
  org_id: string().required(),
  // updated_at: string(),
  // updated_by: string(),

  /** @todo use NewRecipeComponentSchema */
  RecipeComponents: array(RecipeComponentSchema)
    .uniqueProperty("feed_component_id", 'Doppelte Komponente')
    .required(),
  /** @todo use NewRecipesAusladegruppenSchema */
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema)
    .uniqueProperty("ausladegruppe_id", 'Doppelte Ausladegruppe')
    .required(),
})

export const UpdatedRecipeSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  id: string().required(),
  name: string().required(),
  org_id: string().required(),
  // updated_at: string(),
  // updated_by: string(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable(),

  RecipeComponents: array(RecipeComponentSchema)
    .uniqueProperty("feed_component_id", 'Doppelte Komponente')
    .required(),
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema)
    .uniqueProperty("ausladegruppe_id", 'Doppelte Ausladegruppe')
    .required()
})

export const RecipeSchema = object({
  created_at: string().required(),
  created_by: string().required(),
  id: string().required(),
  name: string().required(),
  org_id: string().required(),
  updated_at: string().defined(),
  updated_by: string().defined(),
  deleted_at: string().nullable(),
  deleted_by: string().nullable(),

  RecipeComponents: array(RecipeComponentSchema)
    .uniqueProperty("feed_component_id", 'Doppelte Komponente')
    .required(),
  RecipesAusladegruppen: array(RecipesAusladegruppenSchema)
    .uniqueProperty("ausladegruppe_id", 'Doppelte Ausladegruppe')
    .required()
})

/** @deprecated */
export type NewRecipe = InferType<typeof NewRecipeSchema>
/** @deprecated */
export type Recipe = InferType<typeof RecipeSchema>

export const RecipeCreateSchema = NewRecipeSchema
export const RecipeUpdateSchema = UpdatedRecipeSchema

export type RecipeDto = InferType<typeof RecipeSchema>
export type RecipeCreateDto = InferType<typeof RecipeCreateSchema>
export type RecipeUpdateDto = InferType<typeof RecipeUpdateSchema>
// export type RecipeDeleteDto = InferType<typeof UpdatedRecipeSchema>

/** @deprecated */
type _MyNewRecipesAusladegruppen = Omit<
  RecipesAusladegruppen,
  "recipe_id" | CreatedPropsKeys | UpdatedPropsKeys>
/** @deprecated */
type _MyNewRecipeComponent = Omit<
  RecipeComponent,
  "recipe_id" | CreatedPropsKeys | UpdatedPropsKeys>
/**
 * @ deprecated
 * Used by fe-api ;-(
 */
export type MyNewRecipe = Omit<
  Recipe,
  "id" | CreatedPropsKeys | UpdatedPropsKeys | "RecipesAusladegruppen" | "RecipeComponents"
> /* & Partial<UpdatedProps> */ & {
  RecipesAusladegruppen: _MyNewRecipesAusladegruppen[],
  RecipeComponents: _MyNewRecipeComponent[]
}

// type Prettify<T> = {
//   [K in keyof T]: T[K];
// } & {};
// type asdf = Prettify<MyNewRecipeComponent>
// type asdf2 = Prettify<MyNewRecipe>
