/**
 * Properties of a FeedingReport.
 */

import { array, InferType, number, object, string } from "yup"
import { MessageTimestampSchema } from "./MessageTimestampSchema"

const MessageKomponenteSchema = object({
  id: string().required().uuid(),
  Menge: number().required().positive(),
  Mischzeit: number().required().min(0),
  Bezeichnung: string().optional()
})
const MessageAusladegruppeSchema = object({
  id: string().required().uuid(),
  Tieranzahl: number().required().integer(), // .positive(), -- for now, any number is allowed.,
  Bezeichnung: string().optional()
})
const MessageAufgenommeneMengeSchema = object({
  id: string().required().uuid(),
  Menge: number().required(), // .positive(), -- for now, any number is allowed.
  Mischzeit: number().optional().min(0),
  Bezeichnung: string().optional()
})
const MessageAusgebrachteMengeSchema = object({
  id: string().required().uuid(),
  Menge: number().required(), // .positive(), -- for now, any number is allowed.
  Bezeichnung: string().optional()
})
export const PostFeedingReportMessageSchema = object({
  clientId: string().required(),
  sessionId: string().required(),
  resTopic: string().required(),
  data: object({
    /** Recipe's ID */
    id: string().required().uuid(),
    /** Recipe's Name */
    Bezeichnung: string().optional(),
    /** @deprecated Timestamp with undefined meaning */
    Zeitstempel: string().optional(),
    /** uuid of the Driver */
    Fahrer: string(), // .uuid() -- for now, the name is passed as a string.
    Startzeit: MessageTimestampSchema,
    Endzeit: MessageTimestampSchema,
    Komponenten: array().of(MessageKomponenteSchema).required().min(1),
    Ausladegruppen: array().of(MessageAusladegruppeSchema).required().min(1),
    AufgenMeng: array().of(MessageAufgenommeneMengeSchema).required().min(1),
    AusgebrMeng: array().of(MessageAusgebrachteMengeSchema).required().min(1),
  }).required()
})

export const PostFeedingReportReplySchema = object({
  sessionId: string().required(),
  res: object({
    code: string().required(),
    message: string().required(),
    feedingReportId: string().required()
  }).required()
})

export type PostFeedingReportMessageDto = InferType<typeof PostFeedingReportMessageSchema>
export type PostFeedingReportReplyDto = InferType<typeof PostFeedingReportReplySchema>

export const FeedingReportSchema = object({
  clientId: string(),
  org_id: string().required(),
  // created_at: string().required(),
  // created_by: string().required(),
  // updated_at: string().required(),
  // updated_by: string().required(),
  feedingReportId: string().required(),
  startTime: string().required(),
  endTime: string().required(),
  driverId: string(),
  recipeId: string().required(),
  recipeName: string(),
  Ausladegruppen: array().of(MessageAusladegruppeSchema),
  Komponenten: array().of(MessageKomponenteSchema),
  AufgenMeng: array().of(MessageAufgenommeneMengeSchema),
  AusgebrMeng: array().of(MessageAusgebrachteMengeSchema),
})

export type FeedingReportDto = InferType<typeof FeedingReportSchema>

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
type pfrmDto = Prettify<PostFeedingReportMessageDto>
type pfrrDto = Prettify<PostFeedingReportReplyDto>
