import { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Controller, FormProvider, SubmitHandler, UseFieldArrayRemove, useFieldArray, useForm, useFormContext } from "react-hook-form"
import { Button, Flex, Label, Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react"
import { del, post, put } from "aws-amplify/api"
import { Cache } from 'aws-amplify/utils'
import { FaGripLines, FaRegTrashCan } from "react-icons/fa6"

import { FeedComponentDto, RecipeCreateDto, RecipeCreateSchema, Organization, RecipeDto, RecipeComponent, RecipesAusladegruppen, RecipeUpdateDto, RecipeUpdateSchema } from "sds"
import { ResponseMessage } from "../../../../../../@types/sds"

import config from "../../../config"
import { UNIT_OF_MASS } from "../../../org-settings"
import { fakes } from "../../../messe-fakes"
import { currentSession } from "../../../Utils.AmplifyAuth"
import Modal from "../../../components/Modal"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { getLinkToOrgRecipes } from "../../../LinkUtils"
import { imageHash } from "../-components/ListItem"
import { transformInputToNumber, transformInputToSeconds, transformNumberToString, transformSecondsToMinutes } from "./RecipeUtils"

import '../RecipeManagement.css'

const NUM_RC_IMAGES = config.images.rc.numImages
const IMAGE_HASH_SEED = config.images.rc.imageHashSeed

type ComponentModes = "create" | "edit" | "invalid"

const getEmptyRecipe = (org: Organization): RecipeDto => {
  return {
    id: '',
    org_id: org.id,
    name: '',
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: '',
    RecipeComponents: [
      getEmptyRecipeComponent()
    ],
    RecipesAusladegruppen: [
      getEmptyAusladegruppe()
    ]
  }
}
const getEmptyRecipeComponent = (order: number = 1): RecipeComponent => {
  return {
    order: order,
    feed_component_id: '',
    mass_kg: 0,
    mixing_time_s: 0 * 60,
    recipe_id: '',
  }
}
const getEmptyAusladegruppe = (order: number = 1): RecipesAusladegruppen => {
  return {
    order: order,
    ausladegruppe_id: '',
    recipe_id: '',
  }
}

interface RecipeAusladegruppenProps {
  org: Organization
}
function RecipeAusladegruppen({ org }: RecipeAusladegruppenProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const { fields, append, remove, swap, move } = useFieldArray({
    name: "RecipesAusladegruppen",
  })

  let isAddDisabled = fields.length >= config.limits.numberOfAnimalGroupsPerRecipe
  let renderedOrder = 1
  return <>
    <h3 className="!font-semibold">
      Ausladegruppen
    </h3>
    <Table className="recipe-ausladegruppen" size="small">
      <TableHead>
        <TableRow>
          <TableCell as="th">Rhf.</TableCell>
          <TableCell as="th">Name</TableCell>
          <TableCell as="th">Anz. Tiere</TableCell>
          <TableCell as="th">{/* Buttons */}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fields.map((ra, index) => <RecipeAusladegruppenEntry key={ra.id}
          org={org}
          order={renderedOrder++}
          ra={ra}
          index={index}
          remove={remove} />)}
      </TableBody>
    </Table>
    {/* Render the "Add" button */}
    <div className="mt-4">
      <Button disabled={isAddDisabled} onClick={() => {
        append(getEmptyAusladegruppe(fields.length + 1))
      }}>Ausladegruppe hinzufügen</Button>
    </div>
  </>
}

interface RecipeAusladegruppenEntryProps {
  org: Organization
  order: number
  ra: Record<"id", string>
  index: number
  remove: UseFieldArrayRemove
}
function RecipeAusladegruppenEntry({ org, order, ra, index, remove }: RecipeAusladegruppenEntryProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const defaultAg = { number_of_animals: -1 }

  const [agId, setAgId] = useState<string | undefined>((ra as any).ausladegruppe_id as string | undefined)
  const [ag, setAg] = useState<{ number_of_animals: number }>(defaultAg)

  useEffect(() => {
    // console.log("effect runs")
    const newAg = agId
      ? UserDataContextHelper.findAnimalGroup(org, agId)
      : defaultAg
    setAg(newAg)
    // console.log("ag <-", agx)
  }, [agId])
  // console.log("entry", order, "renders, num =", ag.number_of_animals)

  return <TableRow key={ra.id}>
    <TableCell>
      {/* <FaGripLines className="inline" /> */}
      {order}
    </TableCell>
    <TableCell>
      <select
        {...register(`RecipesAusladegruppen.${index}.ausladegruppe_id`, {
          required: true,
          onChange: (event) => {
            setAgId(event.target.value)
            // console.log("onChange, agId <-", event.target.value)
          }
        })}
        /** @ts-ignore */
        className={(errors?.RecipesAusladegruppen && errors.RecipesAusladegruppen[index] && errors.RecipesAusladegruppen[index]?.ausladegruppe_id) ? "error" : ""}
      >
        <option key="" value="">Auswählen...</option>
        {org.Ausladegruppen
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(ag => <option key={ag.id} value={ag.id}>{ag.name}</option>)}
      </select>
    </TableCell>
    <TableCell className="anz">{ag.number_of_animals}</TableCell>
    <TableCell>
      <Button variation="warning" className="!border-none" onClick={() => remove(index)}>
        <span role="img" aria-label="remove"><FaRegTrashCan /></span>
      </Button>
    </TableCell>
  </TableRow>
}

interface RecipeComponentsListProps {
  org: Organization
}
function RecipeComponentsList({ org }: RecipeComponentsListProps) {
  const { fields, append, remove, swap, move } = useFieldArray({
    name: "RecipeComponents",
  })

  let isAddDisabled = fields.length >= config.limits.numberOfFeedComponentsPerRecipe
  let renderedOrder = 1
  return <>
    <h3 className="!font-semibold">
      Komponenten
    </h3>
    <Table className="recipe-components" size="small">
      <TableHead>
        <TableRow>
          <TableCell as="th">Rhf.</TableCell>
          <TableCell as="th">Komponente</TableCell>
          <TableCell as="th">
            Frischmasse<br />
            {UNIT_OF_MASS} pro Tier
          </TableCell>
          {/* <TableCell as="th">
            Nachmischzeit<br />
            Minuten
          </TableCell> */}
          <TableCell as="th">{/* Buttons */}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* Render components from the recipe */}
        {fields.map((field, index) => <RecipeComponentsEntry key={field.id}
          availableFeedComponents={org.FeedComponents}
          order={renderedOrder++}
          index={index}
          remove={remove} />)}
      </TableBody>
    </Table>
    {/* Render the "Add" button */}
    <div className="mt-4">
      <Button disabled={isAddDisabled} onClick={() => {
        append(getEmptyRecipeComponent(fields.length + 1))
      }}>Komponente hinzufügen</Button>
    </div>
  </>
}

interface RecipeComponentsEntryProps {
  order: number
  availableFeedComponents: FeedComponentDto[]
  index: number
  remove: UseFieldArrayRemove
}
function RecipeComponentsEntry({ availableFeedComponents, order, index, remove }: RecipeComponentsEntryProps) {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()

  // console.log("errors", errors)
  return <>
    <TableRow>
      <TableCell>
        {/* <FaGripLines className="inline" /> */}
        {order}
      </TableCell>
      <TableCell>
        <select
          {...register(`RecipeComponents.${index}.feed_component_id`, { required: "Wählen Sie eine Komponente aus.", })}
          /** @ts-ignore */
          className={(errors?.RecipeComponents && errors.RecipeComponents[index] && errors.RecipeComponents[index]?.feed_component_id) ? "error" : ""}
        >
          <option key="" value="">Auswählen...</option>
          {availableFeedComponents
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(fc => <option key={fc.id} value={fc.id}>{fc.name}</option>)}
        </select>
      </TableCell>
      <TableCell className="fm">
        <Controller
          control={control}
          name={`RecipeComponents.${index}.mass_kg`}
          render={({ field, fieldState: { error, invalid } }) => <input
            {...field}
            /** @ts-ignore */
            className={"w-20" + ((errors?.RecipeComponents && errors.RecipeComponents[index] && errors.RecipeComponents[index]?.mass_kg) ? " error" : "")}
            onBlur={(e) => field.onChange(transformInputToNumber(e.target.value))}
            value={transformNumberToString(field.value)}
          />
          }
          rules={{
            min: {
              value: 0.00000001,
              message: "Geben Sie eine positive Masse ein."
            },
          }}
        />
      </TableCell>
      {/* <TableCell className="mt">
        <Controller
          control={control}
          name={`RecipeComponents.${index}.mixing_time_s`}
          render={({ field, fieldState: { error, invalid } }) => <input
            {...field}
            /** @ts-ignore * /
            className={"w-12" + ((errors?.RecipeComponents && errors.RecipeComponents[index] && errors.RecipeComponents[index]?.mixing_time_s) ? " error" : "")}
            onBlur={(e) => field.onChange(transformInputToSeconds(e.target.value))}
            value={transformSecondsToMinutes(field.value)}
          />
          }
          rules={{
            required: false,
            min: {
              value: 0,
              message: "Die Mischzeit darf nicht negativ sein."
            }
          }}
        />
        {/** @todo allow fractional input * /}
      </TableCell> */}
      <TableCell>
        <Button variation="warning" className="!border-none" onClick={() => { remove(index) }}>
          <span role="img" aria-label="remove"><FaRegTrashCan /></span>
        </Button>
      </TableCell>
    </TableRow>
    {(errors?.RecipeComponents && (errors.RecipeComponents as any)[index])
      && <TableRow className="errors"><TableCell colSpan={5}>{
        Object.keys((errors.RecipeComponents as any)[index]).map((fieldName) => {
          return <div>Fehler: {(errors.RecipeComponents as any)[index][fieldName].message}</div>
        })}
      </TableCell></TableRow>
    }
  </>
}

export default function RecipeCreateEdit() {
  const params = useParams()
  const navigate = useNavigate()
  const { userData, reloadUserDataAsync } = useContext(UserDataContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const isReadOnly = !fakes.roles.rw.includes(userData?.id || "anonymous user")
  const isCreateMode = window.location.pathname.endsWith('/new')
  const isEditMode = window.location.pathname.endsWith('/edit')
    && params.orgId
    && params.recipeId
  const componentMode: ComponentModes = isCreateMode
    ? "create"
    : isEditMode
      ? "edit"
      : "invalid"
  const modalCallback = async (value: string) => {
    console.log("> modalCallback:", value)
    if (value
      .trim()
      .toLowerCase() === 'ja') {
      console.log("  wanna delete: " + recipe.id)
      await deleteRecipe(recipe.id)
      /** @todo set global success message with data from `recipe` */

      console.log("  Invalidating Cache")
      const cacheKey = "frontendApi#/users/current"
      await Cache.removeItem(cacheKey)

      if (reloadUserDataAsync) {
        console.log("  Reloading data")
        await reloadUserDataAsync()
      } else {
        console.warn("  Not reloading data; callback not set")
      }

      console.log("  Navigating back")
      navigate(getLinkToOrgRecipes(org))
    } else {
      console.log("  NO wanna delete")
    }
    setShowDeleteModal(false)
  }

  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  // const recipes = AppContextHelper.findRecipes(org)
  const recipe: RecipeDto = isEditMode
    ? UserDataContextHelper.findRecipe(org, params.recipeId!)
    : getEmptyRecipe(org)

  const methods = useForm<RecipeDto>({
    values: recipe,
    mode: "onBlur"
  })
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = methods

  if (componentMode === "invalid") {
    return <div>Errrr... invalid component mode</div>
  }

  const onSubmit: SubmitHandler<RecipeDto> = async (data) => {
    console.log("▶️ onSubmit")
    if (isReadOnly) {
      console.log("🚧 Read-only mode.")
      return
    }
    setIsFormSubmitting(true)
    if (isCreateMode) {
      data.id = "new"
    }

    // /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    // let isOrigValid: boolean = false
    // if (isCreateMode) {
    //   if (RecipeCreateSchema.isValidSync(data)) {
    //     console.log("✅ Original data is valid")
    //     isOrigValid = true
    //   } else {
    //     console.warn("❌ Original data is not valid")
    //     try {
    //       await RecipeCreateSchema.validate(data, { abortEarly: false })
    //     } catch (err) {
    //       /** @ts-ignore */
    //       console.log("Validation errors", err.errors)
    //     }
    //   }
    // } else {
    //   if (RecipeUpdateSchema.isValidSync(data)) {
    //     console.log("✅ Original data is valid")
    //     isOrigValid = true
    //   } else {
    //     console.warn("❌ Original data is not valid")
    //     try {
    //       await RecipeUpdateSchema.validate(data, { abortEarly: false })
    //     } catch (err) {
    //       /** @ts-ignore */
    //       console.log("Validation errors", err.errors)
    //     }
    //   }
    // }
    // if (!isOrigValid) {
    //   return false
    // }

    const cleaned = cleanData(data, isCreateMode)
    // console.log('clean', clean)
    console.log("\n"
      + `${cleaned.name}\n`
      + `  Komponenten\n`
      + cleaned.RecipeComponents.map(rco => {
        const fcId = rco.feed_component_id
        const fc = UserDataContextHelper.findFeedComponent(org, fcId)
        return `    ${fc.name}\t${rco.mass_kg} ${UNIT_OF_MASS}\t${rco.mixing_time_s} s`
      }).join("\n")
      + "\n"
      + `  Ausladegruppen\n`
      + cleaned.RecipesAusladegruppen.map(rag => {
        const agId = rag.ausladegruppe_id
        const ag = UserDataContextHelper.findAnimalGroup(org, agId)
        return `    ${ag.name}\t${ag.number_of_animals} T`
      }).join("\n")
    )
    /** @todo Implement proper client-side validation (different for 'create' and 'update') */
    let validated: RecipeCreateDto | RecipeUpdateDto | undefined = undefined
    if (isCreateMode) {
      if (RecipeCreateSchema.isValidSync(cleaned)) {
        console.log("✅ Cleaned data is valid")
        validated = cleaned
      } else {
        console.warn("❌ Cleaned data is not valid")
        try {
          validated = await RecipeCreateSchema.validate(cleaned, { abortEarly: false })
        } catch (err) {
          /** @ts-ignore */
          console.log("Validation errors", err.errors)
        }
      }
    } else {
      if (RecipeUpdateSchema.isValidSync(cleaned)) {
        console.log("✅ Cleaned data is valid")
        validated = cleaned
      } else {
        console.warn("❌ Cleaned data is not valid")
        try {
          validated = await RecipeUpdateSchema.validate(cleaned, { abortEarly: false })
        } catch (err) {
          /** @ts-ignore */
          console.log("Validation errors", err.errors)
        }
      }
    }
    if (!validated) {
      setIsFormSubmitting(false)
      return false
    }
    const result = isEditMode
      ? await updateRecipe(recipe.id, cleaned)
      : await createRecipe(cleaned)
    /** @todo Result is meaningless? */
    /** @todo Error handling */

    console.log("Invalidating Cache")
    const cacheKey = "frontendApi#/users/current"
    await Cache.removeItem(cacheKey)

    if (reloadUserDataAsync) {
      console.log("  Reloading data")
      await reloadUserDataAsync()
    } else {
      console.warn("  Not reloading data; callback not set")
    }

    setIsFormSubmitting(false)
    console.log("Navigating back")
    navigate(getLinkToOrgRecipes(org))

    return result
  }

  /** Cleans the form data before submission. */
  function cleanData(data: RecipeDto, isCreateMode: boolean): RecipeDto {
    console.debug("▶️ cleanData")
    const cleaned: RecipeDto = JSON.parse(JSON.stringify(data))
    cleaned.RecipesAusladegruppen.forEach(elem => {
      /** @ts-ignore */
      delete elem.updated_at
      /** @ts-ignore */
      delete elem.updated_by
    })
    cleaned.RecipeComponents.forEach(elem => {
      /** @ts-ignore */
      delete elem.updated_at
      /** @ts-ignore */
      delete elem.updated_by
    })
    if (isCreateMode) {
      cleaned.RecipesAusladegruppen.forEach(elem => {
        /** @ts-ignore */
        delete elem.created_at
        /** @ts-ignore */
        delete elem.created_by
      })
      cleaned.RecipeComponents.forEach(elem => {
        /** @ts-ignore */
        delete elem.created_at
        /** @ts-ignore */
        delete elem.created_by
      })
    }
    return cleaned
  }
  const prepareApiCall = async (path: string, body: any = false) => {
    console.debug("▶️ prepareApiCall")
    const tokens = await currentSession()
    const apiName = 'frontendApi'
    const options = {
      headers: {
        Authorization: `Bearer ${tokens?.idToken}`
      },
      body: body
    }
    if (!body) {
      delete options.body
    }
    console.info(`Prepared API call to ${path}`)
    return { apiName, path, options }
  }
  async function createRecipe(data: RecipeCreateDto) {
    console.log("▶️ createRecipe")
    const apiCallParams = await prepareApiCall(`/recipes`, data)
    const restOperation = post(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function updateRecipe(recipeId: string, data: RecipeUpdateDto): Promise<ResponseMessage> {
    // -> PUT
    console.log("▶️ updateRecipe")
    const apiCallParams = await prepareApiCall(`/recipes/${recipeId}`, data)
    const restOperation = put(apiCallParams)
    const apiResponse = await (await restOperation.response).body.json() as unknown as ResponseMessage
    console.log("Got API response:", apiResponse)
    console.log("Got restOperation.response:", restOperation.response)
    return apiResponse
  }
  async function deleteRecipe(recipeId: string) {
    console.log("▶️ deleteRecipe", recipeId)
    const apiCallParams = await prepareApiCall(`/recipes/${recipeId}`)
    const restOperation = del(apiCallParams)
    const statusCode: number = (await restOperation.response).statusCode
    console.log("Got API response with statusCode:", statusCode)
    console.log("Got restOperation.response:", restOperation.response)
    return statusCode
  }

  const imageIdx = imageHash(IMAGE_HASH_SEED, recipe.id, NUM_RC_IMAGES)
  const imageSrc = `/images/recipes/ration-pixabay-${imageIdx}.jpg`

  return <>
    <div className="rm-edit-page">
      <div className="hdr-img" style={{
        background: `transparent url('${imageSrc}') 0% 0% no-repeat padding-box`
      }} />
      <div className="content-overlay">
        <div className="header">
          <div className="info">
            <h2>{recipe.name}</h2>
          </div>
        </div>

        <div className="details">
          {/* pass all methods into the context */}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <Flex direction="column" gap="small">
                <Flex>
                  <Label htmlFor="name">Rezeptname:</Label>
                  <input
                    id="name"
                    {...register("name", { required: "Geben Sie einen Rezeptnamen an." })} placeholder={recipe.name || 'Neues Rezept'}
                    className={errors?.name ? "error" : ""}
                  />
                  {errors?.name ? "Fehler: " + errors?.name.message : ""}
                </Flex>

                <RecipeComponentsList org={org} />

                <RecipeAusladegruppen org={org} />

                <div className="buttons">
                  <Button disabled={isFormSubmitting || isReadOnly} variation="primary" type="submit">
                    Speichern
                  </Button>
                  <Link to="..">
                    <Button disabled={isFormSubmitting}>Abbrechen</Button>
                  </Link>
                  {componentMode === "edit"
                    && <>
                      <Button disabled={isFormSubmitting || isReadOnly} variation="warning" onClick={() => { setShowDeleteModal(true) }}>Löschen</Button>
                      <Modal buttons={[
                        {
                          text: 'Ja',
                          variation: "destructive"
                        },
                        { text: 'Nein' }
                      ]} show={showDeleteModal} callback={modalCallback}>
                        <div className="title">
                          Rezept wirklich löschen?
                        </div>
                        <div>
                          {`Wollen Sie das Rezept "${recipe.name}" wirklich löschen?`}
                        </div>
                      </Modal>
                    </>}
                </div>
              </Flex>
            </form>
          </FormProvider>

          {/* {componentMode === "edit"
            &&
            <div className="mt-5 text-xs">
              <div>Zuletzt bearbeitet am {recipe.updated_at} von {recipe.updated_by}</div>
              <div>Angelegt am {recipe.created_at} von {recipe.created_by}</div>
            </div>
          } */}
        </div>
      </div>
    </div>

    {/* <small>* Die Icons "xmark (light)", "pen-to-square (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
