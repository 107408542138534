import { useContext } from "react"
import { useParams } from "react-router-dom"
import { FaCow } from "react-icons/fa6"

import { AnimalGroupDto } from "sds"

import config from "../../../config"
import { UserDataContext, UserDataContextHelper } from "../../../contexts/UserDataContextProvider"
import { ListPage } from "../-components/ListPage"
import AnimalGroupItem from "../-components/AnimalGroupItem"

import '../RecipeManagement.css'

export default function AnimalGroups() {
  const params = useParams()
  const { userData } = useContext(UserDataContext)
  const org = UserDataContextHelper.findOrg(params.orgId!, userData!)
  const animalGroups = UserDataContextHelper.findAnimalGroups(org)

  return <>
    <ListPage
      _className="animal-groups"
      icon={<FaCow className="w-5 _h-3.5_ relativ text-[#3E9E29]" />}
      title="Ausladegruppen"
      items={animalGroups.sort((a, b) => a.name < b.name ? -1 : 1)}
      maxNumItems={config.limits.numberOfAnimalGroups}
      renderFunc={(item: AnimalGroupDto, index: number) => <AnimalGroupItem key={item.id} org={org} item={item} />}
    />

    {/* <small>* Die Icons "sliders-up", "eye (light)" benötigen FontAwesome Pro, kostet 49,- oder 99,- pro Jahr.</small> */}
  </>
}
